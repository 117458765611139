import React, { useEffect, useRef, useState } from "react";
import ChatActiveMicrophone from "../ChatActiveMicrophone";
import SoundwaveTransition from "./SoundwaveTransition";

/**
 * ChatHistoryAudio component
 * @param {Array} chatHistory - Array of chat messages
 */
const ChatHistoryAudio = ({
  activityCoundownTimerComplete,
  isDataUploaded,
  prompt,
  systemSpeaking,
  systemSpeakingRef,
  isSilentForFiveSeconds,
  isTalkingForFiveSeconds,
  startedTalking,
  toggle,
  type,
  prevType,
  // processText,
  audioPlayed,
  audioPlayedRef,
  setAudioPlayed,
  userStoppedTalking,
  setUserStoppedTalking,
  isRecording,
  submitBtnClicked
}) => {

  // const [audioPlayed, setAudioPlayed] = useState(false);
  const chatEndRef = useRef(null);

  // 'You are a helpful assistant providing concise responses in at most two sentences.'
  // useEffect(() => {
  //   chatEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   // console.log("CHATHISTORY CHANGED: chatHistory", chatHistory, "chatTurnCounter:", chatTurnCounter);
  // }, [chatHistory]);

  useEffect(() => {
    console.log(
      "ChatHistory007:",
      // chatHistory,
      "PROMPT:",
      prompt,
      "SystemSpeaking:",
      systemSpeaking,
      "ActivityCountdownTimerComplete:",
      activityCoundownTimerComplete,
      "Type:",
      type,
      "isDataUplodaded:",
      isDataUploaded,
      // "setMicrophoneSpeakTimeComplete:",
      // setMicrophoneSpeakTimeComplete
    );
  }, [systemSpeaking, prompt]);



  return (
    <div className="chat-container-audio">
      {/* REMOVE CHAT HISTORY FOR NOW */}
      {/* {chatHistory.map((message, index) => (
        <div key={index} className={`chat-message ${message.role}`}>
          <div className="chat-bubble">{message.content}</div>
        </div>
      ))} */}
      <div ref={chatEndRef} />

      <div className="microphone-toggle">
        {/* MOVED TO THE END */}
        <SoundwaveTransition
          isTimerComplete={activityCoundownTimerComplete}
          audioPlayed={audioPlayed}
          classLabel="system-response"
          setAudioPlayed={setAudioPlayed}
          audioPlayedRef={audioPlayedRef}
          systemSpeaking={systemSpeaking}
          systemSpeakingRef={systemSpeakingRef}
          /*************/
          isTalkingForFiveSeconds={isTalkingForFiveSeconds}
          isSilentForFiveSeconds={isSilentForFiveSeconds}
          startedTalking={startedTalking}
          prevType={prevType}
          type={type}
          isRecording={isRecording}
          isDataUploaded={isDataUploaded}
        />

        {/* {console.log("AUDIO TYPE:", type)} */}
        <ChatActiveMicrophone
          isTimerComplete={activityCoundownTimerComplete}
          type={type}
          toggle={toggle}
          prevType={prevType}
          isDataUploaded={isDataUploaded}
          classLabel=""
          // recordingDuration={90}
          // recordingUnit="seconds"
          startedTalking={startedTalking}
          systemSpeaking={systemSpeaking}
          isSilentForFiveSeconds={isSilentForFiveSeconds}
          isTalkingForFiveSeconds={isTalkingForFiveSeconds}
          userStoppedTalking={userStoppedTalking}
          setUserStoppedTalking={setUserStoppedTalking}
          isRecording={isRecording}
          submitBtnClicked={submitBtnClicked}
        />


      </div>

      {/* <SoundwaveTransition
            isTimerComplete={passageAudioCountdownComplete}
            startSpeaking={startSpeaking}
            setStartSpeaking={setStartSpeaking}
            audioPlayed={audioPlayed}
            stepperLabel="passage-foreign"
            classlabel="system-response"
          />
        </div>

          <div className="microphone-container">
            <div className="microphone">
              {displaySvgIcon("inactive-microphone")}
            </div>
          </div> */}
    </div>
  );
};

export default ChatHistoryAudio;
