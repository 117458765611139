import React from "react";
import BlockImageContainer from "./BlockImageContainer";
import displaySvgIcon from "../utils/displaySvgIcon";

/**
 * RowContainer component organizes and displays images and labels 
 * grouped into rows, and it adds special labels like "Original Plan" 
 * and "New Plan" before certain rows.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.currentScenario - The current scenario object that contains an array of images.
 * @param {string} props.originalLabel - The label for the "Original Plan."
 * @param {string} props.newLabel - The label for the "New Plan."
 * @returns {JSX.Element} The rendered list of rows with images and labels.
 */
const RowContainer = ({ currentScenario, originalLabel, newLabel }) => {
  const images = currentScenario?.images;

  // This function displays an SVG icon (a down arrow in this case)
  const displaySVG = () => {
    return <>{displaySvgIcon("down-arrow")}</>;
  };

  /**
   * This goes through each row of the images array and sets up an elements array,
   * which is an array of BlockImages, consisting of an image and a label, 
   * which will be rendered in a 2 by 2 column grid
   **/
  const displayImages = images?.flatMap((row, rowIndex) => {
    const elements = [];

    // If the row is the first row (rowIndex 0) or fourth row (rowIndex 3), display images and labels
    if (rowIndex === 0 || rowIndex === 3) {
      const labels = images[rowIndex + 1]; // Get the labels from the next row
      elements.push(
        <div key={`row-${rowIndex}`} className={`row-${rowIndex}`}>
          <BlockImageContainer images={row} labels={labels} />
        </div>
      );
    }
    // If it's the third row (rowIndex 2), display the SVG icon
    else if (rowIndex === 2) {
      elements.push(
        <div key={`svg-icon-${rowIndex}`} className="svg-icon">
          {displaySVG()}
        </div>
      );
    }

    // Add "Original Plan" label before the first row
    if (rowIndex === 0) {
      elements.splice(0, 0, (
        <div key="original-plan" className="original-container plan">
          {originalLabel}
        </div>
      ));
    }

    // Add "New Plan" label before the fourth row
    if (rowIndex === 3) {
      elements.splice(0, 0, (
        <div key="new-plan" className="new-container plan gp-70">
          {newLabel}
        </div>
      ));
    }

    return elements;
  });

  // This organizes how the rows will be displayed, wrapping the first two rows and last two rows
  const wrappedDisplayImages = (
    <>
      <div className="original">
        {displayImages?.slice(0, 2)}
      </div>
      {displayImages && displayImages[2]} {/* This shows the SVG icon */}
      <div className="new">
        {displayImages && displayImages.slice(3)}
      </div>
    </>
  );

  return <>{wrappedDisplayImages}</>; // Returns the entire structure for display
};

export default RowContainer;
