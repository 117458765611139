import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  listAll,
  ref,
  getDownloadURL,
  getStorage,
} from "firebase/storage";

const downloadFolderAsZip = async (setIsDownloading) => {
  const storage = getStorage();
  const jszip = new JSZip();
  const folderRef = ref(storage, "pte-a");

  try {
    setIsDownloading(true); // Set downloading state to true when starting

    const root = await listAll(folderRef);
    const promises = root.prefixes.map(async (outerFolder) => {
      const innerFolderRelativePath = outerFolder._location.path_;
      const innerFolderRef = ref(storage, innerFolderRelativePath);
      const innerFolderItems = await listAll(innerFolderRef);

      const filesPromises = innerFolderItems.items.map(async (file) => {
        const fileRelativePath = file._location.path_;
        const parsedPath = fileRelativePath.split("/");
        const fileName = parsedPath[parsedPath.length - 1];
        const userId = parsedPath[parsedPath.length - 2];

        const fileRef = ref(storage, fileRelativePath);
        const realFileUrl = await getDownloadURL(fileRef);
        const fileBlob = await fetch(realFileUrl).then((response) => response.blob());

        const userFolder = jszip.folder(userId);
        userFolder.file(fileName, fileBlob);

        return file;
      });

      await Promise.all(filesPromises);
    });

    await Promise.all(promises);
    const blob = await jszip.generateAsync({ type: "blob" });
    saveAs(blob, "download.zip");

  } catch (error) {
    console.error("Error downloading files:", error);
  } finally {
    setIsDownloading(false); // Reset downloading state when finished
  }
};

const DownloadButton = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (props.signedIn) {
      downloadFolderAsZip(setIsDownloading);
    }
  }, [props.signedIn]);

  return (
    <button
      className={`btn ${props.variant} ${props.size} ${props.classLabel}`}
      onClick={() => {
        if (props.signedIn) {
          console.log("1:Signed in");
        } else {
          console.log("2:Not signed in");
        }
      }}
      disabled={props.disabled || isDownloading}
    >
      {isDownloading ? "Downloading..." : props.text}
    </button>
  );
};

export default DownloadButton;
