import React, { useState, useRef, useEffect } from "react";
import {
  processPassageAudio,
  processAudio,
  processText,
  playAudio,
  playSystemAudio,
  stopSystemAudioPlayback,
  processChatAudio,
  processAdviceAudio,
  detectLanguageWithWhisper
} from "../../../../utils/OpenAi";

import useCheckSilence from "../../../../utils/hooks/useCheckSilence";
import { detectLanguageFromAudio } from "../../../../utils/detectLanguageFromAudio"
import { combineAudioBlobs } from "../../../../utils/combineAudioBlobs";
/**
 * Higher-Order Component (HOC) to provide recording functionality to a wrapped component.
 * @param {React.Component} WrappedComponent - The component to wrap with recording functionality.
 * @returns {React.Component} - The wrapped component with added recording functionality.
 */
const withRecording = (WrappedComponent) => {
  return (props) => {
    const wordDetectedRef = useRef(null);

    const [isRecording, setIsRecording] = useState(false);
    const [dataArray, setDataArray] = useState(null);
    const [analyser, setAnalyser] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);

    const [audioHistory, setAudioHistory] = useState([]);
    const [systemAudioBlobs, setSystemAudioBlobs] = useState([]);
    const [userAudioBlobs, setUserAudioBlobs] = useState([]);
    const [combinedBlobs, setCombinedBlobs] = useState([]);
    const [systemAndUserAudio, setSystemAndUserAudio] = useState(null);

    const [passageData, setPassageData] = useState(null);
    const [activityCountdownTimerComplete, setActivityCountdownTimerComplete] =
      useState(false);
    const [wordDetected, setWordDetected] = useState(wordDetectedRef.current);
  
    const initialQuestionAskedRef = useRef(false);
    const {
      silentAfterTalking4AtLeast5Seconds,
      isSilentForFiveSeconds,
      isTalkingForFiveSeconds,
      startedTalking,
      toggle,
      type,
      rms,
      reset,
    } = useCheckSilence(
      analyser,
      isRecording,
      dataArray,
      activityCountdownTimerComplete
    );
    const audioChunks = useRef([]);
    const mediaRecorder = useRef(null);

    // Define refs for system media recorder and system audio chunks
    /***********************************************************/
    const systemMediaRecorder = useRef(null); // For system audio
    const systemAudioChunks = useRef([]); // Separate audio chunks for system audio
    // const systemAudioBlobRef = useRef(null)
    /***********************************************************/
    const audioContext = useRef(null);
    const animationFrameId = useRef(null);
    const isSilentRef = useRef(isSilentForFiveSeconds);
    const textRef = useRef();

    /********************************
     * Setup microphone for recording
     * and calls process Audio
     * @returns {Promise<void>}
     *********************************/
    const startRecording = async (
      isSystemAudioResponseRequested,
      initialSystemQuestion,
      comType,
      systemPrompt,
      currentActivityType,
      chatTurnCounter,
      nextBtnClicked
    ) => {
      const chatInteraction =
        comType === "spoken" &&
        systemPrompt &&
        currentActivityType === "chat" &&
        typeof chatTurnCounter === "number" &&
        typeof initialSystemQuestion === "string";

      console.log("chatInteraction:", chatInteraction);

      if (chatInteraction) {
        console.log(
          "STARTING RECORDING: systemPrompt:",
          systemPrompt,
          "ComType:",
          comType,
          "CurrentActivityType:",
          currentActivityType,
          "chatTurnCounter:",
          chatTurnCounter,
          "initialSystemQuestion:",
          initialSystemQuestion
        );
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        audioContext.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        mediaRecorder.current = new MediaRecorder(stream);
        /*****************************
         * Step1: Start Audio Recording:
         * When MediaRecorder has started
         * Everytime data is available you will
         * add it to your chunksarray
         *****************************/

        mediaRecorder.current.ondataavailable = (event) => {
          /************************************************************
           * IMPORTANT: MEDIA_RECORDER EXIT CONDITION:
           * In order to stop processing jump data from a
           * previous scenario, we must delete the previous
           * recording audio chunks, when the next button is clicked.
           * (1) Identify next button is clicked
           * (2) Delete previous audio chunks
           *************************************************************/
          // (1) Identify next button click
          console.log("Next button was clicked! submitBtnClicked:1",
            nextBtnClicked.current
          );
          if (nextBtnClicked.current) {
            console.log(
              "Next button was clicked! submitBtnClicked:1",
              nextBtnClicked.current
            );
            wordDetectedRef.current = null;
            setChatHistory([]);
            setAudioHistory([]);

            // (2) Delete previous audio chunks
            audioChunks.current = []; // Clear audio chunks
            reset();
            return; // Exit if next button is clicked
          }
          /***************************************************************/
          if (event.data.size > 0) {
            audioChunks.current.push(event.data);
          }
        };
        /*****************************************************************
         * Step 5: Create the arguments analyser, isRecording, dataArray, to be used by
         * the useCheckSilence hook, to filter out silence and trim audio
         *****************************************************************/
        const audioInput = audioContext.current.createMediaStreamSource(stream);
        const analyserNode = audioContext.current.createAnalyser();
        analyserNode.fftSize = 2048; // Ensure this is set
        audioInput.connect(analyserNode);

        setAnalyser(analyserNode);
        const newDataArray = new Uint8Array(analyserNode.fftSize);
        setDataArray(newDataArray);
        console.log("Recording starting");
        mediaRecorder.current.start();

        console.log("Recording started");

        let analysisPromise; // Declare a variable to hold the analysis promise

        // Start analyzing audio if nextBtn is not clicked
        if (!nextBtnClicked.current) {

          /***********************************
           * This is required to get it working
           ************************************/
          analysisPromise = true;
          wordDetectedRef.current = analysisPromise;
          console.log("Is speaking detected:", wordDetectedRef.current);
          setWordDetected(analysisPromise);
          /******************************/
        }

        /*************************************************
         * Step 2: Stop Audio Recording: Create audioBlob
         * When MediaRecorder has been stopped
         * trim Audio Sample and send for processing
         **************************************************/
        mediaRecorder.current.onstop = async () => {
          if (nextBtnClicked.current) {
            wordDetectedRef.current = null;
            return; // Exit if next button is clicked
          }


          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          // const audioBlob2 = new Blob(audioChunks.current, {
          //   type: "audio/wav",
          // });
          /**********************************************************
           * THIS IS WHERE YOU SHOULD CHECK IF THE AUDIO IS ENGLISH
           * USING LANGID, IF NOT SET WORDDETECTED TO NULL
           **********************************************************/
          console.log("AUDIOBLOB.SIZE:", audioBlob.size);
          // Example usage
          if (audioBlob.size > 0) {
            //WhisperAI Failed to detect language
            // let isEnglishBlob;
            // detectLanguageWithWhisper(audioBlob2)
            // .then((language) => {
            //   console.log(`Detected Language: ${language}`);
            //   isEnglishBlob = language;
            // })
            // .catch(console.error);
            const isEnglishBlob = await detectLanguageFromAudio(audioBlob);
            try {
              if (isEnglishBlob) {
                console.log("HasWord:", isEnglishBlob);
                wordDetectedRef.current = isEnglishBlob;
                setWordDetected(isEnglishBlob);
              }
              else
                wordDetectedRef.current = null;
            } catch (error) {
              console.error("Error during transcription or checking for English words:", error);
            }
          }
          /*******************************************/
          console.log("audioChunks:", audioChunks);

          audioChunks.current = [];
          /************************
           * Play AudioBlob
           ************************/
          console.log("ChatBaseRecording ln 205: PlayAudioBlob: AudioBlobSize:", audioBlob.size)
          // playAudioBlob(audioBlob);

          /*************************************
           * Step 4: Process audio when noise is
           * detected and transcribe data
           *************************************
           * IMPORTANT: Checks if audio is a word
           * in Chat activityType, but not in Advice
           * activityType
           **************************************/
          if (!isSilentRef.current && audioBlob.size > 0) {
            let dataObject = null;
            /***************************************
             * PROCESSAUDIO:  If audio has been
             * collected then send to the processAudio
             * to trim and remove silent portions after
             * the "I'm done" button clicked or page
             * transitioned automatically.
             ***************************************/
            if (chatInteraction) {
              console.log("Processing Audio");
              dataObject = await processChatAudio(
                isSystemAudioResponseRequested,
                initialSystemQuestion,
                systemPrompt,
                chatTurnCounter,
                audioBlob,
                chatHistory,
                setChatHistory,
                false,
                false,
                audioHistory,
                setAudioHistory,
                wordDetectedRef.current,
                initialQuestionAskedRef.current,
                nextBtnClicked,
                wordDetectedRef,
                userAudioBlobs,
                setUserAudioBlobs,
              );
            } else if (currentActivityType === "passage") {
              dataObject = await processPassageAudio(
                audioBlob,
                chatHistory,
                setChatHistory,
                false,
                false,
                wordDetectedRef.current,
                currentActivityType
              );
            } else if (currentActivityType === "advice") {
              dataObject = await processPassageAudio(
                audioBlob,
                chatHistory,
                setChatHistory,
                false,
                false,
                wordDetectedRef.current,
                currentActivityType
              );
            } else {
              dataObject = await processAudio(
                audioBlob,
                chatHistory,
                setChatHistory,
                false,
                false,
                wordDetectedRef.current
              );
            }

            console.log("dataObject:", dataObject, "chatHistory:", chatHistory);
            /**************************************
             * IMPORTANT: Passage Activity Type uses this
             * to Trigger the next page after nextBtn
             * Clicked
             **************************************/
            if (currentActivityType === "passage" || !chatInteraction) {
              setPassageData(dataObject);
            }
          }
        }; // onstop
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    };
    //end startRecording

    /**
     * Step0 of 5: Stop microphone and checkSilence Looper
     */
    const stopRecording = async () => {
      try {
        // Stop the MediaRecorder
        if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
          mediaRecorder.current.stop();
          console.log("Recording stopped.");

          // Optionally process the audio data
          const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
          audioChunks.current = []; // Clear the chunks for future recordings
          console.log("Recording stopped and audio processed.");
        } else {
          console.log("MediaRecorder is not active or already stopped.");
        }
      } catch (error) {
        console.error("Error stopping recording:", error);
      }
    };

    useEffect(() => {
      setWordDetected(wordDetectedRef.current);
      console.log(
        "Should come after wordDetected is false",
        "wordDetectedRef:",
        wordDetectedRef,
        "isRecording:",
        isRecording
      );
      if (wordDetectedRef.current === false) {
        console.log("wordDetectedRef:", wordDetectedRef);
        // setIsRecording(false);
      }
    }, [wordDetected]);

/**
 * COMBINE SYSTEM AUDIO AND USER AUDIO
 */
useEffect(() => {
  if (systemAudioBlobs.length > 0 || userAudioBlobs.length > 0) {
    let newCombinedArray = [];

    // 1. Ensure the first item is from systemAudioBlobs
    if (systemAudioBlobs.length > 0) {
      newCombinedArray = [systemAudioBlobs[0]]; // Add the first systemAudioBlob as the first item
    }

    // 2. Keep track of the index for both arrays
    let systemIndex = 1; // Start from the second element of systemAudioBlobs
    let userIndex = 0;   // Start from the first element of userAudioBlobs

    // 3. Add remaining elements alternately between userAudioBlobs and systemAudioBlobs
    while (userIndex < userAudioBlobs.length || systemIndex < systemAudioBlobs.length) {
      // Add from userAudioBlobs if available
      if (userIndex < userAudioBlobs.length) {
        newCombinedArray.push(userAudioBlobs[userIndex]);
        userIndex++; // Move to the next userAudioBlob
      }

      // Add from systemAudioBlobs if available
      if (systemIndex < systemAudioBlobs.length) {
        newCombinedArray.push(systemAudioBlobs[systemIndex]);
        systemIndex++; // Move to the next systemAudioBlob
      }
    }

    // 4. Update the combinedBlobs state with the new array
    setCombinedBlobs(newCombinedArray);
  }
}, [systemAudioBlobs, userAudioBlobs]); // Run this effect whenever systemAudioBlobs or userAudioBlobs changes


useEffect(() => {
  console.log("CombinedBlobs:", combinedBlobs);

  const combineAudioArray = async () => {
    if (combinedBlobs.length > 0) {
      // if (combinedBlobs.length >= 3) {
        // Test that audio plays
      const combinedAudioBlob = await combineAudioBlobs(combinedBlobs);
      console.log("CombinedAudioBlob:", combinedAudioBlob)

      // playAudioBlob(combinedAudioBlob);
      setSystemAndUserAudio(combinedAudioBlob)
    }
  }// handleAudioHistory

  combineAudioArray();
}, [combinedBlobs]);


    return (
      <WrappedComponent
        {...props}
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        passageData={passageData}
        activityCountdownTimerComplete={activityCountdownTimerComplete}
        setActivityCountdownTimerComplete={setActivityCountdownTimerComplete}
        isSilentRef={isSilentRef}
        isSilentForFiveSeconds={isSilentForFiveSeconds}
        silentAfterTalking4AtLeast5Seconds={silentAfterTalking4AtLeast5Seconds}
        isTalkingForFiveSeconds={isTalkingForFiveSeconds}
        startedTalking={startedTalking}
        toggle={toggle}
        type={type}
        startRecording={startRecording}
        // startSystemRecording={startSystemRecording}
        // systemMediaRecorder={systemMediaRecorder}
        // systemAudioBlobRef={systemAudioBlobRef}
        stopRecording={stopRecording}
        // stopSystemRecording={stopSystemRecording}
        setPassageData={setPassageData}
        chatHistory={chatHistory}
        setChatHistory={setChatHistory}
        audioHistory={audioHistory}
        setAudioHistory={setAudioHistory}
        combinedBlobs={combinedBlobs}
        setCombinedBlobs={setCombinedBlobs}
        systemAndUserAudio={systemAndUserAudio}
        setSystemAndUserAudio={setSystemAndUserAudio}
        textRef={textRef}
        systemAudioBlobs={systemAudioBlobs}
        setSystemAudioBlobs={setSystemAudioBlobs}
        setUserAudioBlobs={setUserAudioBlobs}
        processText={processText}
        processAudio={processAudio}
        playAudio={playAudio}
        playSystemAudio={playSystemAudio}
        wordDetectedRef={wordDetectedRef}
        wordDetected={wordDetected}
        rms={rms}
        reset={reset}
        stopSystemAudioPlayback={stopSystemAudioPlayback}
        initialQuestionAskedRef={initialQuestionAskedRef}
      />
    );
  };
};

export default withRecording;
