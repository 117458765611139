import { transcribeAudio } from "./OpenAi"
import langid from 'langid';

// Function to clean the transcribed text
const cleanText = (text) => {
  return text
    .replace(/[^\w\s]|_/g, '')  // Remove punctuation
    .replace(/\s+/g, ' ')       // Replace multiple spaces with a single space
    .trim();                    // Trim whitespace from start and end
};

export const detectLanguageFromAudio = async (audioBlob) => {
  const transcribedText = await transcribeAudio(audioBlob); // Your transcription function

  if (!transcribedText) {
    console.error("Transcription failed.");
    return null;
  }

  // Clean the transcribed text
  const cleanedText = cleanText(transcribedText);
  console.log("Cleaned Transcribed Text:", cleanedText);

  // Use langid to detect the language of the cleaned transcribed text
  const detections = langid.detect(cleanedText);
  console.log("Detected Language Codes:", detections);

  // Convert array of arrays to an array of objects and limit to the first six
  const languageData = detections.slice(0, 6).map(([langCode, confidence]) => ({
    language: langCode,
    confidence: confidence,
  }));

  console.log("Language Data as Objects:", languageData);
  

  // Check if 'english' exists in the languageData array
  const englishRecord = languageData.find(lang => lang.language === 'english');

  let isEnglishDetected = false;

  // Check if there is an English record
  if (englishRecord) {
    const firstRecord = languageData[0];

    // If the first record is English, return true
    if (firstRecord.language === 'english') {
      isEnglishDetected = true;
    } 
    // If the first record is not English, calculate confidence offset
    else {
      const confidenceOffset =  Math.floor(firstRecord.confidence - englishRecord.confidence);

      // Check if the adjusted confidence is less than or equal to 0.018
      // if (confidenceOffset <= 0.018) {
        if (confidenceOffset <= 0.05) {

        console.log("Adjusted Confidence:", confidenceOffset);
        isEnglishDetected = true; // Return true if the condition is met
      } 
      // Check for English with a minimum confidence threshold
      else if (englishRecord.confidence >= 0.23) {
        isEnglishDetected = true;
      }
    }
  } 

  console.log("Is English Detected:", isEnglishDetected);

  return isEnglishDetected; // Return true if English is detected, otherwise false
};

