import OpenAI from "openai";
import { trimSilenceFromAudioBlob } from "./audio";
import { ensureMinimumAudioLength } from "./ensureMinimumAudioLength";
import { combineAudioBlobs } from "./combineAudioBlobs";
// import {outputAudioBlob} from "./outputAudioBlob";
import { playAudioBlob } from "./playAudioBlob";
import { convertTranscriptionToText } from "./convertTranscriptionToText";

export const SILENCE_THRESHOLD = 1.0;
export const silenceMessage = "Are you still there?";

export const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPEN_AI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const calculateRMS = (dataArray) => {
  const squares = dataArray.map((value) => (value - 128) * (value - 128));
  const meanSquare =
    squares.reduce((sum, value) => sum + value, 0) / dataArray.length;
  return Math.sqrt(meanSquare);
};

/**
 * Configure the WhipserApi request fo Get the transcribed Audio 
 * @param {} audioBlob
 * @returns
 */
export const transcribeAudio = async (audioBlob) => {
  const file = new File([audioBlob], "output.wav", { type: "audio/wav" });
  const formData = new FormData();
  formData.append("file", file);
  formData.append("model", "whisper-1");
  formData.append("response_format", "text");
  // formData.append("language", "en");
  // formData.append("task","transcribe");
  //   formData.append("language", "en");

  try {
    // Send the recorded audio to OpenAI's Whisper API for transcription
    const transcriptionResponse = await fetch(
      "https://api.openai.com/v1/audio/transcriptions",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_API_KEY}`,
        },
        body: formData,
      }
    );

    console.log("transcriptionResponse:", transcriptionResponse);

    // Check if the response is okay
    if (transcriptionResponse.ok) {
      // Check the Content-Type of the response
      const contentType = transcriptionResponse.headers.get("content-type");
      console.log("Content-Type:", contentType);

      // Depending on the content type, parse the response appropriately
      let transcribedText;
      if (contentType && contentType.includes("application/json")) {
        // Parse as JSON if the content type is JSON
        const transcribedRes = await transcriptionResponse.json();
        console.log("Transcribed Response:", transcribedRes);
        transcribedText = transcribedRes.text; // Access the transcribed text
      } else if (contentType && contentType.includes("text/plain")) {
        // If it's plain text, read it as text
        transcribedText = await transcriptionResponse.text();
        console.log("Transcribed Text (Plain):", transcribedText);
      } else {
        throw new Error("Unexpected content type: " + contentType);
      }

      console.log("Transcription Data:", transcribedText);
      return transcribedText; // Return the transcribed text
    } else {
      throw new Error(
        `HTTP error ${transcriptionResponse.status}: ${transcriptionResponse.statusText}`
      );
    }
  } catch (error) {
    console.error("Error in transcribeAudio:", error);
    return null; // or throw error if you want to propagate the error further
  }

};

const fetch = require('node-fetch'); // Make sure to install this package if using Node.js

/********************************************************************
 * Function to detect the language of an audioBlob using Whisper API
 ********************************************************************/
export const detectLanguageWithWhisper = async (audioBlob) => {
  if (!audioBlob || audioBlob.size <= 0) {
    throw new Error('Invalid audio blob');
  }

  try {
    // Convert the Blob to FormData
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.wav'); // Append the Blob as a file with a filename
    formData.append("model", "whisper-1");
    // Send the request to Whisper API
    const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPEN_AI_API_KEY}`, // Replace with your actual OpenAI API key
        'Accept': 'application/json' // Accept JSON response
      },
      body: formData,
    });

    // Handle non-200 responses
    if (!response.ok) {
      const errorBody = await response.text(); // Capture error details
      throw new Error(`Error: ${response.statusText}, Details: ${errorBody}`);
    }

    // Parse the JSON response
    const result = await response.json();

    // Check the structure of the response to get the detected language
    console.log('API Response:', result); // Log the entire response for debugging

    const detectedLanguage = result.language || 'Language not detected'; // Adjust based on actual response structure
    console.log('Detected Language:', detectedLanguage);

    return detectedLanguage; // Return the detected language
  } catch (error) {
    console.error('Error processing audio:', error);
    throw error; // Rethrow the error for handling upstream
  }
};

/****************************
 * AUDIO STOP PLAY VARIABLES
 ******************************/
// let currentSource = null;
// let currentAudioContext = null;

// Function to play text/or the AI assistant's response as audio
// Returns true when audio has been played.
export const playAudio = async (
  text,
  currentSourceRef,
  currentAudioContextRef
) => {
  let lastRequestTime = 0;
  const requestDelay = 1000; // Time in milliseconds (e.g., 1 second)

  /*****************************
   * Step 0: Check if text is provided
   *****************************/
  if (text) {
    console.log("playAudio:", text);
    // do not play Whisper audio response
    const url = "https://api.openai.com/v1/audio/speech";

    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_API_KEY}`,
    };
    /********************************
     * Step1: Prepare data for the API request
     *********************************/
    const data = {
      model: "tts-1",
      input: text,
      voice: "echo",
      response_format: "mp3",
    };

    try {
      // Implementing a delay to prevent too many requests
      const currentTime = Date.now();
      if (currentTime - lastRequestTime < requestDelay) {
        await new Promise((resolve) => setTimeout(resolve, requestDelay - (currentTime - lastRequestTime)));
      }
      lastRequestTime = Date.now();

      /********************************************
       * Step 2: Setup API Request
       *********************************************/
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      });

      console.log("PlayAudio Response:", response);
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const arrayBuffer = await response.arrayBuffer();
      const currentAudioContext = new (window.AudioContext || window.webkitAudioContext)();

      if (currentAudioContextRef) {
        currentAudioContextRef.current = currentAudioContext;
      }

      /**************************************
       * Step 3: Play the Audio
       *****************************************/
      const audioBuffer = await currentAudioContext.decodeAudioData(arrayBuffer);
      let currentSource = currentAudioContext.createBufferSource();
      currentSource.buffer = audioBuffer;
      currentSource.connect(currentAudioContext.destination);

      if (currentSourceRef) {
        currentSourceRef.current = currentSource;
      }

      return new Promise((resolve) => {
        currentSource.onended = () => {
          resolve(true);
        };
        currentSource.start(0);
      });
    } catch (error) {
      console.error("Error in playAudio:", error);
      return false;
    }
  } else {
    return false;
  }
};

export const playSystemAudio = async (
  text,
  currentSourceRef,
  currentAudioContextRef,
  systemAudioBlobs,
  setSystemAudioBlobs
) => {


  if (text) {
    console.log("playAudio:", text);

    const url = "https://api.openai.com/v1/audio/speech";
    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_API_KEY}`,
    };

    const data = {
      model: "tts-1",
      input: text,
      voice: "echo",
      response_format: "mp3",
    };

    try {

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      });

      console.log("PlayAudio Response:", response);
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const arrayBuffer = await response.arrayBuffer();
      const currentAudioContext = new (window.AudioContext || window.webkitAudioContext)();

      if (currentAudioContextRef) {
        currentAudioContextRef.current = currentAudioContext;
      }

      // Decode the audio buffer
      const audioBuffer = await currentAudioContext.decodeAudioData(arrayBuffer);

      // Create a MediaStream destination
      const mediaStreamDestination = currentAudioContext.createMediaStreamDestination();

      // Initialize MediaRecorder with the destination stream
      const mediaRecorder = new MediaRecorder(mediaStreamDestination.stream);
      const audioChunks = [];

      // Capture audio data
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      };

      // Start recording
      mediaRecorder.start();

      // Create the audio source from the decoded buffer
      const currentSource = currentAudioContext.createBufferSource();
      currentSource.buffer = audioBuffer;
      currentSource.connect(currentAudioContext.destination);
      currentSource.connect(mediaStreamDestination); // Connect to the MediaStream

      if (currentSourceRef) {
        currentSourceRef.current = currentSource;
      }

      return new Promise((resolve) => {
        currentSource.onended = () => {
          // Stop the recording
          mediaRecorder.stop();

          // When recording stops, create the final audio blob
          mediaRecorder.onstop = async () => {
            const finalAudioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            console.log('Final audio blob created:', finalAudioBlob);

            // You can now play, download, or use the finalAudioBlob as needed
            // playAudioBlob(finalAudioBlob); // Example: play the WAV blob

            if (finalAudioBlob.size > 0 && finalAudioBlob instanceof Blob) {

              setSystemAudioBlobs([...systemAudioBlobs, finalAudioBlob])

            }
            resolve(true);

          };

          // // resolve(true);
          // resolve(finalAudioBlob);
        };

        // Start playing the audio
        currentSource.start(0);
      });
    } catch (error) {
      console.error("Error in playAudio:", error);
      return false;
    }
  } else {
    return false;
  }
};



export const stopSystemAudioPlayback = (currentSource, currentAudioContext) => {
  console.log("stopSystemAudioPlayback: Stopping playback");
  if (currentSource && currentAudioContext) {
    try {
      // Stop the audio source if it's not already stopped
      if (currentSource.stop) {
        currentSource.stop();
      }

      // Check if the audio context is already closed
      if (currentAudioContext.state !== "closed") {
        currentAudioContext.close();
      }

      console.log("Audio playback stopped.");
    } catch (error) {
      console.error("Error stopping audio playback:", error);
    }
    // currentSource = null;
    // currentAudioContext = null;
    console.log("Audio playback stopped.");
  }
};
/*********************
 * ProcessChatAudio
 ********************/
export const processChatAudio = async (
  isSystemAudioResponseRequested,
  initialSystemQuestion,
  systemPrompt,
  chatTurnCounter,
  audioBlob,
  chatHistory,
  setChatHistory,
  isSystemResponseRequested,
  isSystemTranscriptionRequested,
  audioHistory,
  setAudioHistory,
  wordDetected,
  initialQuestionAsked,
  nextBtnClicked,
  wordDetectedRef,
  userAudioBlobs,
  setUserAudioBlobs,
) => {
  const newScenarioDetected =
    chatHistory.length > 0 &&
    chatHistory[0].role === "assistant" &&
    chatHistory[0].content !== initialSystemQuestion;
  console.log(
    "1ProcessingAudio: WordDetected:",
    wordDetected,
    "initialSystemQuestion:",
    initialSystemQuestion,
    "initialQuestionAsked:",
    initialQuestionAsked,
    "ChatTurnCounter:",
    chatTurnCounter,
    "chatHistory:",
    chatHistory,
    "nextBtnClicked:",
    nextBtnClicked,
    "newScenarioDetected:",
    newScenarioDetected,
    wordDetectedRef
  );
  const resetChatHistory = [];
  const resetAudioHistory = [];
  const submitBtnClicked = nextBtnClicked.current;
  /********************************
   * (1) EARLY TERMINATION:
   * If nextBtnClicked or
   * the next scenario is
   * detected.
   **********************************/
  if (nextBtnClicked.current || newScenarioDetected) {
    wordDetectedRef.current = null;
    setChatHistory(resetChatHistory);
    setAudioHistory(resetAudioHistory);
    return false;
  }

  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const audioRecorded = audioBlob.size > 0;
  console.log("AUDIOBLOB:", audioBlob, "audioRecorded:", audioRecorded);

  let chatResponseText = "";
  let trimmedAudioBlob = null;
  let adjustedAudioBlob = null;

  /*****************************************
   * Step1: Make sure the audio is in the correct format
   ******************************************/
  if (wordDetected) {
    trimmedAudioBlob = await trimSilenceFromAudioBlob(audioBlob, audioContext);
    adjustedAudioBlob = await ensureMinimumAudioLength(trimmedAudioBlob);
  }

  /**********************************************
   * Step 2: If a word is detected begin transcribing the audio
   * Otherwise make the transcribed text an empty string
   **********************************************/
  try {
    const transcribedText =
      wordDetected && adjustedAudioBlob !== null
        ? await transcribeAudio(adjustedAudioBlob)
        : "";

    console.log(
      "transcribedText2:",
      transcribedText,
      "TURNCOUTNER:",
      chatTurnCounter
    );

    let messages;

    /**************************************
     * Step 3: Store the Transcribed Audio into the
     * message to be sent as part of the message
     * history and prompt for chatGPT
     **************************************/
    // prepend the assistant's first message to the chat history
    let preliminaryChat = [];

    /*******************************************
     * IMPORTANT: If we are on firstTurnCounter then
     * (1) Prepare the preliminary chat message with the
     * original system question and user response.
     * (2) Add the preliminary message to chatGPT request
     * (3) Else if transcribedText and chatCounter > 1,
     * add the user response to the chatGPT request,
     * in addition to the chat history
     ********************************************/
    // if (chatTurnCounter === 1 && transcribedText) {
    if (chatTurnCounter === 1) {
      // (1) pair preliminary chat with response
      preliminaryChat = [
        {
          role: "assistant",
          content: initialSystemQuestion,
          turnCounter: 0,
        },
        { role: "user", content: transcribedText, turnCounter: 0 },
      ];

      // (2) Add the preliminary message to chatGPT request
      messages = [
        {
          role: "system",
          content: systemPrompt,
        },
        ...preliminaryChat,
      ];
    }

    /***************************************************
     * (3) Add ChatHistory while submit button not clicked
     * reset chatHistory if submit button clicked
     * The conditions for normal
     * chat after
     * preliminary chat has been set
     * with just the user response
     **************************************************/
    if (chatTurnCounter > 1) {
      messages = [
        {
          role: "system",
          content: systemPrompt,
        },
        ...chatHistory,
        { role: "user", content: transcribedText },
      ];
    }

    console.log(
      "ProcessChatAudio: messages:",
      messages,
      "transcribedText:",
      transcribedText,
      "!transcribedText:",
      !transcribedText,
      "chatTurnCounter:",
      chatTurnCounter
    );

    /********************************
     * Step 4: Get ChatGPT Response when
     * chatTurnCounter === 1:
     * (1) If transcribedText is an empty str
     * set chatResponse to the error message.
     * (2) If transcribedText has value then
     * query chatGpt for response.
     *******************************/
    if (!transcribedText) {
      // (1) Set chat respose to error message
      chatResponseText =
        "I'm sorry, I didn't quite catch that. Could you please speak a little louder?";
    } else if (transcribedText) {
      // (2) Query chatGpt for response
      console.log("messages:", messages);
      const chatResponse = await openai.chat.completions.create({
        messages: messages,
        model: "gpt-4",
      });
      chatResponseText = chatResponse.choices[0].message.content;
    }

    console.log(`>> Assistant said: ${chatResponseText}`);
    // const newScenarioDetected = chatHistory.length > 0 && initialSystemQuestionRef.current !== chatHistory[0]?.content;
    // console.log("newScenarioDetected:", newScenarioDetected, "initialSystemQuestionRef.current:", initialSystemQuestionRef.current);
    /*************************************
     * Build the ChatTurn for chatTurnCounter 1
     *************************************/
    let newGeneratedMessages = [];

    /******************************************
     * Step 5: If chatTurnCounter = 1
     * (1) Prepend preliminary chat to newGeneratedMessages
     * (2) Else just use the current responses
     *******************************************/
    if (chatTurnCounter === 1) {
      newGeneratedMessages = [
        ...preliminaryChat,
        {
          role: "assistant",
          content: chatResponseText,
          turnCounter: chatTurnCounter,
        },
      ];
    } else if (chatTurnCounter > 1) {
      /***************************************************
       * (1) Determine if the system is still in the same
       * scenario.
       * (2) If so, return the normal response without
       * prepending the preliminary message
       ****************************************************/
      newGeneratedMessages = [
        {
          role: "user",
          content: transcribedText, // good
          turnCounter: chatTurnCounter, // 2
        },
        {
          role: "assistant",
          content: chatResponseText,
          turnCounter: chatTurnCounter, // 2
        },
      ];
    }

    /******************************************
     * Step 6: Combine the Chat History depending
     * on SUBMIT BUTTON CLICKED
     * (1) Combine the newGeneratedMessages with
     * the existing chatHistory
     * (2) Set the new chatHistory
     *******************************************/
    console.log("newGeneratedMessages:", newGeneratedMessages);
    // (1) Combine the newGeneratedMessages with the existing chatHistory
    const allTranscriptions = [...chatHistory, ...newGeneratedMessages];
    console.log("allTranscriptions:", allTranscriptions);
    // (2) Set the new chatHistory
    setChatHistory(allTranscriptions);

    let allAudioBlobs;
    let combinedAudioBlob;
    /******************************************************
     * Step 7: Combine Audio
     * (1) Only add audio if adjustedAudioBlob was created
     ******************************************************/
    // 8/14/2019: New Version
    if (wordDetected && adjustedAudioBlob !== null) {
      allAudioBlobs = [...audioHistory, adjustedAudioBlob];
      setUserAudioBlobs([...userAudioBlobs, adjustedAudioBlob])
    } else {
      const emptyAudioBlob = new Blob();
      allAudioBlobs = [...audioHistory];
      setUserAudioBlobs([...userAudioBlobs, emptyAudioBlob])
    }

    // Proceed to combine audio blobs only if allAudioBlobs is not empty
    if (allAudioBlobs.length > 0) {
      const combinedAudioBlob = await combineAudioBlobs(allAudioBlobs);
      console.log(
        "combinedAudioBlob",
        combinedAudioBlob,
        "SIZE:",
        combinedAudioBlob.size
      );

      // Update audio history with the combined blob
      setAudioHistory([combinedAudioBlob]);
    } else {
      // Handle the case where there's nothing to combine
      console.log("No audio blobs to combine.");
      setAudioHistory([]);
    }

    return true;
  } catch (error) {
    console.error("Error during transcription or chat:", error);
    return false;
  } // catch
};

/*********************
 * ProcessChatAudio
 ********************/
export const processOpinionAudio = async (
  isSystemAudioResponseRequested,
  initialSystemQuestion,
  systemPrompt,
  chatTurnCounter,
  audioBlob,
  chatHistory,
  setChatHistory,
  isSystemResponseRequested,
  isSystemTranscriptionRequested,
  audioHistory,
  setAudioHistory,
  wordDetected,
  initialQuestionAsked,
  nextBtnClicked,
  wordDetectedRef,
  questions
) => {
  // Define an array of possible thank-you responses
  const thankYouResponses = [
    "Thank you for your response.",
    "I appreciate your input.",
    "Thanks for your feedback.",
    "Your response has been noted.",
    "Thank you for sharing your thoughts."
  ];


  const newScenarioDetected =
    chatHistory.length > 0 &&
    chatHistory[0].role === "assistant" &&
    chatHistory[0].content !== initialSystemQuestion;
  console.log(
    "1ProcessingAudio: WordDetected:",
    wordDetected,
    "initialSystemQuestion:",
    initialSystemQuestion,
    "initialQuestionAsked:",
    initialQuestionAsked,
    "ChatTurnCounter:",
    chatTurnCounter,
    "chatHistory:",
    chatHistory,
    "nextBtnClicked:",
    nextBtnClicked,
    "newScenarioDetected:",
    newScenarioDetected,
    wordDetectedRef,
    "Questions:",
    questions
  );
  const resetChatHistory = [];
  const resetAudioHistory = [];
  const submitBtnClicked = nextBtnClicked.current;
  let numberOfWordsNotDetected =
    chatHistory.length > 0 &&
      chatHistory[chatHistory.length - 1].numberOfWordsNotDetected
      ? chatHistory[chatHistory.length - 1].numberOfWordsNotDetected
      : 0;
  console.log("numberOfWordsNotDetected:", numberOfWordsNotDetected);
  /********************************
   * (1) EARLY TERMINATION:
   * If nextBtnClicked or
   * the next scenario is
   * detected.
   **********************************/
  if (nextBtnClicked.current || newScenarioDetected) {
    wordDetectedRef.current = null;
    setChatHistory(resetChatHistory);
    setAudioHistory(resetAudioHistory);
    return false;
  }

  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const audioRecorded = audioBlob.size > 0;
  console.log("AUDIOBLOB:", audioBlob, "audioRecorded:", audioRecorded);

  let chatResponseText = "";
  let trimmedAudioBlob = null;
  let adjustedAudioBlob = null;

  /*****************************************
   * Step1: Make sure the audio is in the correct format
   ******************************************/
  if (wordDetected) {
    trimmedAudioBlob = await trimSilenceFromAudioBlob(audioBlob, audioContext);
    adjustedAudioBlob = await ensureMinimumAudioLength(trimmedAudioBlob);
  }

  /**********************************************
   * Step 2: If a word is detected begin transcribing the audio
   * Otherwise make the transcribed text an empty string
   **********************************************/
  try {
    const errorCount = wordDetected ? 0 : 1;
    const transcribedText =
      wordDetected && adjustedAudioBlob !== null
        ? await transcribeAudio(adjustedAudioBlob)
        : "";

    console.log(
      "transcribedText2:",
      transcribedText,
      "TURNCOUTNER:",
      chatTurnCounter
    );

    let messages;

    /**************************************
     * Step 3: Store the Transcribed Audio into the
     * message to be sent as part of the message
     * history and prompt for chatGPT
     **************************************/
    // prepend the assistant's first message to the chat history
    let preliminaryChat = [];

    /*******************************************
     * IMPORTANT: If we are on firstTurnCounter then
     * (1) Prepare the preliminary chat message with the
     * original system question and user response.
     * (2) Add the preliminary message to chatGPT request
     * (3) Else if transcribedText and chatCounter > 1,
     * add the user response to the chatGPT request,
     * in addition to the chat history
     ********************************************/
    // if (chatTurnCounter === 1 && transcribedText) {
    if (chatTurnCounter === 1) {
      // (1) pair preliminary chat with response
      preliminaryChat = [
        {
          role: "assistant",
          content: initialSystemQuestion,
          turnCounter: 0,
          numberOfWordsNotDetected,
          errorCount,
        },
        { role: "user", content: transcribedText, turnCounter: 0 },
      ];

      // (2) Add the preliminary message to chatGPT request
      messages = [
        {
          role: "system",
          content: systemPrompt,
        },
        ...preliminaryChat,
      ];
    }

    /***************************************************
     * (3) Add ChatHistory while submit button not clicked
     * reset chatHistory if submit button clicked
     * The conditions for normal
     * chat after
     * preliminary chat has been set
     * with just the user response
     **************************************************/
    if (chatTurnCounter > 1) {
      messages = [
        {
          role: "system",
          content: systemPrompt,
        },
        ...chatHistory,
        {
          role: "user",
          content: transcribedText,
          numberOfWordsNotDetected,
          errorCount,
        },
      ];
    }

    console.log(
      "ProcessChatAudio: messages:",
      messages,
      "transcribedText:",
      transcribedText,
      "!transcribedText:",
      !transcribedText,
      "chatTurnCounter:",
      chatTurnCounter
    );

    /*************************************************
     * Step 4 B: Pull the next question to append to chatResponse:
     *************************************************/
    const nextQuestion =
      questions[chatTurnCounter - numberOfWordsNotDetected];

    // const nextQuestion =
    //   chatTurnCounter <= questions.length ? questions[chatTurnCounter - numberOfWordsNotDetected] : null;

    // const nextQuestion = numberOfWordsNotDetected > 0
    // ? (chatTurnCounter > questions.length
    //     ? questions[chatTurnCounter - numberOfWordsNotDetected - 1]
    //     : null)
    // : (chatTurnCounter <= questions.length
    //     ? questions[chatTurnCounter - numberOfWordsNotDetected]
    //     : null);
    /********************************
     * Step 4: Get ChatGPT Response when
     * chatTurnCounter === 1:
     * (1) If transcribedText is an empty str
     * set chatResponse to the error message.
     * (2) If transcribedText has value then
     * query chatGpt for response.
     *******************************/
    if (!transcribedText) {
      numberOfWordsNotDetected += 1;
      // (1) Set chat respose to error message
      chatResponseText =
        "I'm sorry, I didn't quite catch that. Could you please speak a little louder?";
    } else if (transcribedText) {
      // (2) Query chatGpt for response
      console.log("messages:", messages);
      // const chatResponse = await openai.chat.completions.create({
      //   messages: messages,
      //   model: "gpt-4",
      // });

      // chatResponseText = chatResponse.choices[0].message.content;
      // Select a random response from the array
      const randomResponseIndex = Math.floor(Math.random() * thankYouResponses.length);
      const chatResponse = thankYouResponses[randomResponseIndex];
      // Append the next question if it exists
      chatResponseText = chatResponse;
      if (nextQuestion) {
        chatResponseText += ` ${nextQuestion}`;
      }
    }

    console.log(`>> Assistant said: ${chatResponseText}`);
    // const newScenarioDetected = chatHistory.length > 0 && initialSystemQuestionRef.current !== chatHistory[0]?.content;
    // console.log("newScenarioDetected:", newScenarioDetected, "initialSystemQuestionRef.current:", initialSystemQuestionRef.current);
    /*************************************
     * Build the ChatTurn for chatTurnCounter 1
     *************************************/
    let newGeneratedMessages = [];


    /******************************************
     * Step 5: If chatTurnCounter = 1
     * (1) Prepend preliminary chat to newGeneratedMessages
     * (2) Else just use the current responses
     *******************************************/
    if (chatTurnCounter === 1) {
      newGeneratedMessages = [
        ...preliminaryChat,
        {
          role: "assistant",
          content: chatResponseText,
          turnCounter: chatTurnCounter,
          numberOfWordsNotDetected,
          errorCount,
        },
      ];
    } else if (chatTurnCounter > 1) {
      /***************************************************
       * (1) Determine if the system is still in the same
       * scenario.
       * (2) If so, return the normal response without
       * prepending the preliminary message
       ****************************************************/
      newGeneratedMessages = [
        {
          role: "user",
          content: transcribedText, // good
          turnCounter: chatTurnCounter, // 2
          numberOfWordsNotDetected,
          errorCount,
        },
        {
          role: "assistant",
          content: chatResponseText,
          turnCounter: chatTurnCounter, // 2
          numberOfWordsNotDetected,
          errorCount,
        },
      ];
    }

    /******************************************
     * Step 6: Combine the Chat History depending
     * on SUBMIT BUTTON CLICKED
     * (1) Combine the newGeneratedMessages with
     * the existing chatHistory
     * (2) Set the new chatHistory
     *******************************************/
    console.log("newGeneratedMessages:", newGeneratedMessages);
    // (1) Combine the newGeneratedMessages with the existing chatHistory
    const allTranscriptions = [...chatHistory, ...newGeneratedMessages];
    console.log("chatHistory: allTranscriptions:", allTranscriptions);
    // (2) Set the new chatHistory
    setChatHistory(allTranscriptions);

    let allAudioBlobs;
    let combinedAudioBlob;
    /******************************************************
     * Step 7: Combine Audio
     * (1) Only add audio if adjustedAudioBlob was created
     ******************************************************/
    // 8/14/2019: New Version
    if (wordDetected && adjustedAudioBlob !== null) {
      allAudioBlobs = [...audioHistory, adjustedAudioBlob];
    } else {
      allAudioBlobs = [...audioHistory];
    }

    // Proceed to combine audio blobs only if allAudioBlobs is not empty
    if (allAudioBlobs.length > 0) {
      const combinedAudioBlob = await combineAudioBlobs(allAudioBlobs);
      console.log(
        "combinedAudioBlob",
        combinedAudioBlob,
        "SIZE:",
        combinedAudioBlob.size
      );

      // Update audio history with the combined blob
      setAudioHistory([combinedAudioBlob]);
    } else {
      // Handle the case where there's nothing to combine
      console.log("No audio blobs to combine.");
      setAudioHistory([]);
    }

    return true;
  } catch (error) {
    console.error("Error during transcription or chat:", error);
    return false;
  } // catch
};

/**
 * Fetches transcripts and chatHistory and  trimms AudioBlob File
 * For no ActivityTypes
 **/
export const processAudio = async (
  audioBlob,
  chatHistory,
  setChatHistory,
  isSystemResponseRequested,
  isSystemTranscriptionRequested,
  wordDetected,
  currentActivityType
) => {
  console.log(
    "ProcessingAudio: WordDetected:",
    wordDetected,
    "currentActivityType:",
    currentActivityType
  );
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  // let adjustedAudioBlob = null;
  const audioRecorded = audioBlob.size > 0;
  console.log("AUDIOBLOB:", audioBlob, "audioRecorded:", audioRecorded);
  if (audioRecorded) {
    const trimmedAudioBlob = await trimSilenceFromAudioBlob(
      audioBlob,
      audioContext
    );
    const adjustedAudioBlob = await ensureMinimumAudioLength(trimmedAudioBlob);

    // Play Whisper audio response Only when disableAudio is false
    try {
      // Call TranscribeAudio helper function
      let transcribedText = await transcribeAudio(adjustedAudioBlob);
      if (transcribedText === null) {
        transcribedText = "";
      }
      console.log("transcribedText2:", transcribedText);

      // Prepare the messages for the chat with the AI assistant
      const messages = [
        {
          role: "system",
          content:
            "You are a helpful assistant providing concise responses in at most two sentences.",
        },
        ...chatHistory,
        { role: "user", content: transcribedText },
      ];

      let allTranscriptions = [];
      if (isSystemTranscriptionRequested) {
        // Send the transcribed text to OpenAI's GPT-4 for generating a response
        const chatResponse = await openai.chat.completions.create({
          messages: messages,
          model: "gpt-4",
        });

        let chatResponseText = chatResponse.choices[0].message.content;


        console.log(`>> Assistant said: ${chatResponseText}`);

        if (isSystemResponseRequested) {
          // Play the AI assistant's response as audio
          await playAudio(chatResponseText);
        }

        // Push in chatHistory in allTranscriptions
        allTranscriptions = [
          { chatResponseText },
          ...chatHistory,
          { role: "user", content: transcribedText },
          { role: "assistant", content: chatResponseText },
        ];
      } else {
        allTranscriptions = [
          ...chatHistory,
          { role: "user", content: transcribedText },
        ];
      }

      /***********************
       * Set the chatHistory here
       ***********************/
      setChatHistory(allTranscriptions);

      console.log("****** ProcessAudio:", allTranscriptions, "******");
      // Create the Text to be Uploaded as a file
      // const textBlob = createBlobFromText(allTranscriptions);
      const textBlob = convertTranscriptionToText(allTranscriptions, 'text');
      return {
        transcripts: allTranscriptions,
        audioBlob: adjustedAudioBlob,
        textBlob: textBlob,
      };
    } catch (error) {
      console.error("Error during transcription or chat:", error);
    }
  } else {
    return null;
  }
};
// process Advice audio
export const processAdviceAudio = async (
  audioBlob,
  chatHistory,
  setChatHistory,
  isSystemResponseRequested,
  isSystemTranscriptionRequested,
  wordDetected,
  currentActivityType
) => {
  console.log(
    "ProcessingAudio: WordDetected:",
    wordDetected,
    "currentActivityType:",
    currentActivityType
  );
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  // let adjustedAudioBlob = null;
  const audioRecorded = audioBlob.size > 0;
  console.log("AUDIOBLOB:", audioBlob, "audioRecorded:", audioRecorded);
  if (audioRecorded) {
    const trimmedAudioBlob = await trimSilenceFromAudioBlob(
      audioBlob,
      audioContext
    );
    const adjustedAudioBlob = await ensureMinimumAudioLength(trimmedAudioBlob);

    // Play Whisper audio response Only when disableAudio is false
    try {
      // Call TranscribeAudio helper function
      const transcribedText = await transcribeAudio(adjustedAudioBlob);
      console.log("transcribedText2:", transcribedText);

      // Prepare the messages for the chat with the AI assistant
      const messages = [
        {
          role: "system",
          content:
            "You are a helpful assistant providing concise responses in at most two sentences.",
        },
        ...chatHistory,
        { role: "user", content: transcribedText },
      ];

      let allTranscriptions = [];
      if (isSystemTranscriptionRequested) {
        // Send the transcribed text to OpenAI's GPT-4 for generating a response
        const chatResponse = await openai.chat.completions.create({
          messages: messages,
          model: "gpt-4",
        });

        const chatResponseText = chatResponse.choices[0].message.content;

        console.log(`>> Assistant said: ${chatResponseText}`);

        if (isSystemResponseRequested) {
          // Play the AI assistant's response as audio
          await playAudio(chatResponseText);
        }
        allTranscriptions = [
          { chatResponseText },
          ...chatHistory,
          { role: "user", content: transcribedText },
          { role: "assistant", content: chatResponseText },
        ];
      } else {
        allTranscriptions = [
          ...chatHistory,
          { role: "user", content: transcribedText },
        ];
      }

      /***********************
       * Set the chatHistory here
       ***********************/
      setChatHistory(allTranscriptions);

      console.log("****** ProcessAudio:", allTranscriptions, "******");
      // Create the Text to be Uploaded as a file
      // const textBlob = createBlobFromText(allTranscriptions);
      const textBlob = convertTranscriptionToText(allTranscriptions, 'text');
      return {
        transcripts: allTranscriptions,
        audioBlob: adjustedAudioBlob,
        textBlob: textBlob,
      };
    } catch (error) {
      console.error("Error during transcription or chat:", error);
    }
  } else {
    return null;
  }
};

// Process Passage Audio
export const processPassageAudio = async (
  audioBlob,
  chatHistory,
  setChatHistory,
  isSystemResponseRequested,
  isSystemTranscriptionRequested,
  wordDetected,
  currentActivityType
) => {
  console.log(
    "ProcessingPassageAdviceAudio: WordDetected:",
    wordDetected,
    "currentActivityType:",
    currentActivityType
  );
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  // let adjustedAudioBlob = null;
  const audioRecorded = audioBlob.size > 0;
  console.log("AUDIOBLOB:", audioBlob, "audioRecorded:", audioRecorded);
  let trimmedAudioBlob = null;

  if (audioRecorded && wordDetected) {
    trimmedAudioBlob = await trimSilenceFromAudioBlob(audioBlob, audioContext);
    const adjustedAudioBlob = trimmedAudioBlob
      ? await ensureMinimumAudioLength(trimmedAudioBlob)
      : null;

    // Play Whisper audio response Only when disableAudio is false
    try {
      // Call TranscribeAudio helper function
      const transcribedText = adjustedAudioBlob
        ? await transcribeAudio(adjustedAudioBlob)
        : "";
      console.log("transcribedText2:", transcribedText);

      // Prepare the messages for the chat with the AI assistant
      const messages = [
        {
          role: "system",
          content:
            "You are a helpful assistant providing concise responses in at most two sentences.",
        },
        ...chatHistory,
        { role: "user", content: transcribedText },
      ];

      let allTranscriptions = [];
      if (isSystemTranscriptionRequested) {
        // Send the transcribed text to OpenAI's GPT-4 for generating a response
        const chatResponse = await openai.chat.completions.create({
          messages: messages,
          model: "gpt-4",
        });

        const chatResponseText = chatResponse.choices[0].message.content;

        console.log(`>> Assistant said: ${chatResponseText}`);

        if (isSystemResponseRequested) {
          // Play the AI assistant's response as audio
          await playAudio(chatResponseText);
        }
        allTranscriptions = [
          { chatResponseText },
          ...chatHistory,
          { role: "user", content: transcribedText },
          { role: "assistant", content: chatResponseText },
        ];
      } else {
        allTranscriptions = [
          ...chatHistory,
          { role: "user", content: transcribedText },
        ];
      }

      /***********************
       * Set the chatHistory here
       ***********************/
      setChatHistory(allTranscriptions);

      console.log("****** ProcessAudio:", allTranscriptions, "******");
      // Create the Text to be Uploaded as a file
      // const textBlob = createBlobFromText(allTranscriptions);
      const textBlob = convertTranscriptionToText(allTranscriptions, 'text');
      return {
        transcripts: allTranscriptions,
        audioBlob: adjustedAudioBlob,
        textBlob: textBlob,
      };
    } catch (error) {
      console.error("Error during transcription or chat:", error);
    }
  } else {
    return null;
  }
};
/**
 * Processes the text input by sending it to OpenAI's GPT-4 API for a response.
 * @param {string} userInput - The text input from the user.
 */
export const processText = async (
  userInput,
  chatHistory,
  setChatHistory,
  initialSystemQuestion,
  systemPrompt
) => {
  try {
    console.log(`>> You typed: ${userInput}`);
    console.log("chatHistory:", chatHistory);
    console.log("setChatHistory:", setChatHistory);

    let messages;
    let chatResponse = "";
    let chatResponseText;

    console.log("OPENAI:", chatHistory);
    // Send message history and prompt to chatGPT
    messages = [
      {
        role: "system",
        content: systemPrompt,
      },
      ...chatHistory,
      { role: "user", content: userInput },
    ];

    if (!userInput) {
      chatResponse = initialSystemQuestion;
    } else {
      chatResponse = await openai.chat.completions.create({
        messages: messages,
        model: "gpt-4",
      });
    }

    if (!userInput) {
      chatResponseText = chatResponse;
    } else {
      chatResponseText = chatResponse.choices[0].message.content;
    }

    let allTranscriptions = [
      ...chatHistory,
      ...(userInput ? [{ role: "user", content: userInput }] : []),
      { role: "assistant", content: chatResponseText },
    ];

    setChatHistory(allTranscriptions);
    console.log(`>> Assistant typed: ${chatResponseText}`);
    // await playAudio(chatResponseText);
    console.log("OPENAI: CHATHISTORY:", allTranscriptions);
    const textBlob = convertTranscriptionToText(allTranscriptions, 'text');
    return {
      transcripts: allTranscriptions,
      textBlob: textBlob,
    };
  } catch (error) {
    console.error("Error during chat:", error);
  }
};

// export const createBlobFromText = (data) => {
//   // Map through the array and replace "content" with "User said" or "System said"
//   const textContent = data
//     .map((transcription) => {
//       if (transcription.role === "user") {
//         return `User said: ${transcription.content}`;
//       } else if (transcription.role === "assistant") {
//         return `System said: ${transcription.content}`;
//       }
//     })
//     .join("");

//   // Create a Blob from the text content
//   const textBlob = new Blob([textContent], { type: "text/plain" });

//   return textBlob;
// };
