import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import CountDownTimer from "../../../global/outputs/CountDownTimer";
import { uploadToFirebase } from "../../../utils/firebase";
import displaySvgIcon from "../../../utils/displaySvgIcon";
import {
  updateNextButtonStatus,
  removeActivityScenariosItem,
  updateAdviceValue,
} from "../../../redux/slices/scenarioSlice";
import WithRecording from "../global/hoc/GenericBaseRecording";
import CircularProgressIndicator2 from "../../../global/widgets/CircularProgressIndicator2";
import ActiveMicrophone2 from "../../../global/widgets/ActiveMicrophone2";
import CountDownBar from "../../../global/outputs/CountDownBar";

import { notify } from "../../../utils/notify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";
import { ToastContainer } from "react-toastify";

const AdviceInstructionReview = ({
  nextBtnRef,
  isRecording,
  setIsRecording,
  passageData,
  setPassageData,
  activityCountdownTimerComplete,
  setActivityCountdownTimerComplete,
  isSilentRef,
  isSilentForFiveSeconds,
  isTalkingForFiveSeconds,
  startedTalking,
  toggle,
  type,
  startRecording,
  stopRecording,
  chatHistory,
  setChatHistory,
  wordDetectedRef,
  rms,
}) => {
  /************************************************
   * Redux State Variables
   ***********************************************/
  const scenarioData = useSelector((state) => state.scenarios);
  const globalData = scenarioData.global;
  const currentActivityType = scenarioData.global["current-activity-type"];
  const currentLayout = scenarioData.global["current-layout"];
  const adviceData = scenarioData.advice;

  const adviceScenarios = adviceData.scenarios_copy;
  const currentScenario = adviceData.shiftedItem;
  const currentScenarioComplete = currentScenario.complete;
  // const countdownDuration =
  //   currentScenario && currentScenario["countdown-duration"];
  const countdownDuration = 45;
  // const countdownUnit =
  //   currentScenario && currentScenario["countdown-duration-unit"];
  const countdownUnit = "seconds"

  const recordingDuration =
    currentScenario && currentScenario["recording-duration"];
  const recordingUnit =
    currentScenario && currentScenario["recording-duration-unit"];
  const nextBtnDisabled = globalData["nextBtnDisabled"];
  const allScenariosUploaded = scenarioData.advice["all-scenarios-uploaded"];
  const images = currentScenario?.images;
  /******************************************************
   * React State variables
   ******************************************************/
  const [currentCountdownTime, setCurrentCountdownTime] =
    useState(countdownDuration);
  const [isDataUploaded, setIsDataUploaded] = useState(false);
  const [microphoneSpeakTimeComplete, setMicrophoneSpeakTimeComplete] =
    useState(false);
  const [wordDetectionReset, setWordDetectionReset] = useState(false);
  /************************************************************
   * References
   *************************************************************/
  const lastNotificationCheckRef = useRef(Date.now());

  // The first scenario has been intialized
  const hasInitializedRef = useRef(false);
  /*******************************************************
   * CONSTANTS
   ******************************************************/
  const typeNumber = 2;
  const userId = globalData.id;
  const dispatch = useDispatch();
  /**********************************************************
   * FUNCTIONS:
   **********************************************************/
  /************************************
   * Clears the data for advice when all
   * advice scenarios have been completed
   * and uploaded.  This is a cleanup
   * function before moving to Type 3
   ************************************/
  const setupNextScenario = () => {
    setPassageData(null);
    //08/15/2024 had commented this out
    setIsDataUploaded(false);
    setActivityCountdownTimerComplete(false);
    setMicrophoneSpeakTimeComplete(false);
    setIsRecording(false);
    /******************************************
     * Step 4b: When ActivityCountdownTimerComplete
     * is reset, we are preparing for the next scenario,
     * so reset the chatHistory, so it doesn't accumulate
     * for the next scenario. Done in resetScenario also
     ******************************************/
    setChatHistory([]);
    wordDetectedRef.current = null;
    setWordDetectionReset(false);
  };

  /*************************************
   * Loads the scenario data from redux
   *************************************/
  const handleShiftScenario = () => {
    dispatch(removeActivityScenariosItem("advice"));
    wordDetectedRef.current = null;
    setWordDetectionReset(false);
  };

  /****************************************
   * Renders all the images for the scenario
   *****************************************/
  const displayImages = () => {
    // console.log("currentScenariosMeta:", currentScenario.meta);

    if (currentScenario.meta === "backpain") {
      return (
        <div className="backpain-image-container">
          <div className="image-row">
            {images?.map((image, index) => {
              // Determine the circle color class based on index
              const circleClass = index % 2 === 0 ? "green-circle" : "red-circle";

              return (
                <div className="image-item" key={index}>
                  <div className="image-wrapper">
                    {/* Circle with number and dynamic color class */}
                    <div className={`image-circle ${circleClass}`}>
                      {/* {index + 1} */}
                      {index % 2 === 0 ? displaySvgIcon("thumbsUp") : displaySvgIcon("thumbsDown")}
                    </div>
                    {/* Image */}
                    <img src={image} alt="Scenario" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="image-container">
          {images?.map((image, index) => {
            return (
              <img src={image} alt="Scenario" key={index} />
            )
          })}
        </div>
      );
    }
  };


  /*******************************************************
   * USE EFFECTS
   *******************************************************/
  /************************************************
   * Step 1: Load the initial scenarios
   * (1) Load each data segment each time
   * isDataUploaded is updated
   * (2) Check if all scenarios have been completed
   ************************************************/
  useEffect(() => {
    /********************************************
     * (1) Make sure the data is uploaded for each 
     * data segment if the current data has not been 
     * uploaded and all scenarios have not been 
     * completed
     ********************************************/
    if (!isDataUploaded && !hasInitializedRef.current) {
      if (!allScenariosUploaded) {
        console.log(
          "Calling handleShiftScenario isDataUploaded:",
          isDataUploaded,
          "allScenariosUploaded:",
          allScenariosUploaded,
          "currenatScenarioComplete:",
          currentScenarioComplete,
          "hasInitalized:", hasInitializedRef.current
        );
        handleShiftScenario();
        // set hasInitializedRef to true after first scenario retrieved
        hasInitializedRef.current = true;
      }
      /***************************************************
       * Get the Next scenario only if the currentScenario 
       * is Complete & hasInitializedRef === true
       ***************************************************/
    } else if (hasInitializedRef.current && currentScenarioComplete) {
      handleShiftScenario();
    }

    /************************************
     * (2) If the current scenario data is
     * uploaded, but the advice activity
     * scenario has not been set to complete,
     * Check if we are on the last
     * scenario, if so, set the activity
     * scenario to complete
     ************************************/
    if (isDataUploaded && !allScenariosUploaded) {
      console.log(
        "isDataUploaded:",
        isDataUploaded,
        "adviceScenarios",
        adviceScenarios,
        "Length:",
        adviceScenarios.length
      );

      /*****************************************
       * (2B) If you are on the last scenario and the
       * scenario is complete, mark the advice
       * activty type complete
       *****************************************/
      if (adviceScenarios.length === 0 && isDataUploaded) {
        console.log(
          "LAST ITEM Uploaded: currentScenario:",
          currentScenario,
          "isDataUploaded:",
          isDataUploaded,
          "adviceScenario",
          adviceScenarios,
          "adviceData:",
          adviceData
        );
        dispatch(
          updateAdviceValue({ key: "all-scenarios-uploaded", value: true })
        );
      }
      console.log(
        "Shifting in new items: isDataUploaded:",
        isDataUploaded,
        "allScenariosUploaded:",
        allScenariosUploaded,
        "microphoneSpeakTimeComplete:",
        microphoneSpeakTimeComplete
      );
      setupNextScenario();
    }
    // console.log("currentScenario-1:", currentScenario);
  }, [isDataUploaded]);

  /*****************************************************
   * Step 2: Program Entry Point: Start the Loop Logic
   * (1) Filter the data using global conditions
   * (2) If word not Detected, set is recording to true
   * (3) Else If  word is Detected, set is recording to
   * false
   * (4) Error State: If no word found, notify user
   ****************************************************/
  useEffect(() => {
    const scenarioLoaded =
      currentScenario &&
      typeof currentScenario === "object" &&
      Object.keys(currentScenario).length > 0;

    /******************************************************
     * (1) Data Filtering: wordDetectionReset must be false
     ******************************************************/
    if (
      scenarioLoaded &&
      !currentScenarioComplete &&
      !allScenariosUploaded &&
      !wordDetectionReset
    ) {
      console.log(
        "currentScenario-0:",
        currentScenario,
        "currentScenarioComplete:",
        currentScenarioComplete,
        "wordDetectionReset:",
        wordDetectionReset,
        "activityCountdownTimerComplete:",
        activityCountdownTimerComplete,
        "isRecording:",
        isRecording,
        "StartedTakling:",
        startedTalking,
        "allScenariosUploaded:",
        allScenariosUploaded,
        "isRecording:",
        isRecording,
        "isTalkingForFiveSeconds:",
        isTalkingForFiveSeconds,
        "wordDetected:",
        wordDetectedRef.current,
        "type:",
        type,
        "rms:",
        rms
      );
      /*************************************************
       * (2): If word not detected then set isRecording
       * to true, to start recording.
       *************************************************/
      if (
        activityCountdownTimerComplete &&
        !isRecording &&
        wordDetectedRef.current !== true
      ) {
        console.log(
          "currentScenario-1:",
          currentScenario,
          "activityCountdownTimerComplete:",
          activityCountdownTimerComplete,
          "isRecording:",
          isRecording,
          "isTalkingForFiveSeconds:",
          isTalkingForFiveSeconds,
          "isSilentForFiveSeconds:",
          isSilentForFiveSeconds,
          "Type:",
          type,
          "StartedTakling:",
          startedTalking,
          "StartedTakling:",
          startedTalking,
          "allScenariosUploaded:",
          allScenariosUploaded,
          "isRecording:",
          isRecording,
          "wordDetected:",
          wordDetectedRef.current
        );
        setIsRecording(true);
      }
      // (3) If word is detected, set isRecording to false
      /*******************************************************
       * NOTE: You can not just set is Recording to false if 
       * wordDetectiedRef.current === true, you must also wait
       * the silence
       ******************************************************/

      else if (
        activityCountdownTimerComplete &&
        wordDetectedRef.current === true &&
        isRecording &&
        // isTalkingForFiveSeconds === true &&
        isSilentForFiveSeconds &&
        type === "silent" &&
        !allScenariosUploaded
      ) {
        console.log(
          "currentScenario-2:",
          currentScenario,
          "activityCountdownTimerComplete:",
          activityCountdownTimerComplete,
          "isRecording:",
          isRecording,
          "isTalkingForFiveSeconds:",
          isTalkingForFiveSeconds,
          "isSilentForFiveSeconds:",
          isSilentForFiveSeconds,
          "Type:",
          type,
          "StartedTakling:",
          startedTalking,
          "StartedTakling:",
          startedTalking,
          "allScenariosUploaded:",
          allScenariosUploaded,
          "isRecording:",
          isRecording,
          "wordDetected:",
          wordDetectedRef.current
        );
        setIsRecording(false);
        // wordDetectedRef.current = null;
        setChatHistory([]);
        setWordDetectionReset(true);
      } else if (
        /******************************************
         * (4) Error State: If no word was found in
         * audio, then queue up notify
         * (4A) Reset State
         * (4B) Set wordDetectionReset to true
         ******************************************/
        activityCountdownTimerComplete &&
        wordDetectedRef.current === false &&
        isRecording &&
        isTalkingForFiveSeconds === true &&
        !allScenariosUploaded
      ) {
        console.log(
          "currentScenario-3:",
          currentScenario,
          "activityCountdownTimerComplete:",
          activityCountdownTimerComplete,
          "isRecording:",
          isRecording,
          "isTalkingForFiveSeconds:",
          isTalkingForFiveSeconds,
          "isSilentForFiveSeconds:",
          isSilentForFiveSeconds,
          "Type:",
          type,
          "StartedTakling:",
          startedTalking,
          "allScenariosUploaded:",
          allScenariosUploaded,
          "isRecording:",
          isRecording,
          "wordDetected:",
          wordDetectedRef.current
        );
        // (4A) Reset state variables
        setIsRecording(false);
        wordDetectedRef.current = null;
        setChatHistory([]);

        // (4B) set wordDetectionReset to queue up notify
        setWordDetectionReset(true);
      }
    }
  }, [
    currentScenario,
    // isRecording,
    activityCountdownTimerComplete,
    wordDetectedRef.current,
    type,
    isSilentForFiveSeconds
  ]);

  /*********************************************************
   * Step 3: Queue up Notify:  If no word is detected
   * and wordDetection has been Reset:
   * (1) Reset wordDetectedReset back to false
   * (2) Set isRecording to true
   * (3) Notify user to speak louder
   * ******************************************************/
  useEffect(() => {
    console.log("WordDetectionReset:", wordDetectionReset);
    const checkCondition = () => {
      if (wordDetectionReset && wordDetectedRef.current === null) {
        // (1) Reset wordDetectedReset to false
        setWordDetectionReset((prev) => {
          const newValue = !prev;
          console.log("WordDetectionReset:", newValue);
          // Directly set isRecording to true without waiting for a rerender
          // (2) Set isRecording to true
          setIsRecording(true);
          return newValue; // Return the updated state for setWordDetectionReset
        });

        // (3) Notify user to speak loader
        notify();
        // Update the timestamp to the current time
        lastNotificationCheckRef.current = Date.now();
      }
    };

    // Calculate the time elapsed since the last check
    const timeSinceLastCheck = Date.now() - lastNotificationCheckRef.current;

    // If the last check was more than 5 seconds ago, run checkCondition
    if (timeSinceLastCheck >= 5000) {
      checkCondition();
    }
  }, [wordDetectionReset]);

  /********************************************
   * Step 4: Start or stop recording:
   * (1) If isRecording is true, start recording
   * (2) If isRecording is false, stop recording
   *********************************************/
  useEffect(() => {
    // (1) Once isRecording is true, start recording
    // NOTE:A scenario is marked complete in the Footer
    if (isRecording && !currentScenarioComplete && !allScenariosUploaded) {
      console.log(
        "isRecording:",
        isRecording,
        "startedTalking:",
        startedTalking
      );

      startRecording(null, null, "spoken", null, currentActivityType, null);
    }
    // (2): Stop recording and create the audioBlob
    else if (!isRecording && !allScenariosUploaded) {
      console.log(
        "currentScenario2:",
        currentScenario,
        "currentScenarioComplete:",
        currentScenarioComplete,
        "wordDetectionReset:",
        wordDetectionReset,
        "activityCountdownTimerComplete:",
        activityCountdownTimerComplete,
        "isRecording:",
        isRecording,
        "StartedTakling:",
        startedTalking,
        "allScenariosUploaded:",
        allScenariosUploaded,
        "isRecording:",
        isRecording,
        "isTalkingForFiveSeconds:",
        isTalkingForFiveSeconds,
        "wordDetected:",
        wordDetectedRef.current,
        "type:",
        type,
        "rms:",
        rms
      );
      console.log("Stopping recording")
      stopRecording();
    }
  }, [activityCountdownTimerComplete, isRecording]);

  /*****************************************************
   * Step 5: If passageData received:
   * (1) Re-enable the button
   * NOTE: Clicking the button will execute
   * updateCompleteScenarioByType, which will set
   * currentScenarioComplete to true
   *****************************************************/
  useEffect(() => {
    if (passageData && activityCountdownTimerComplete)
      // (1) re-enable the button
      dispatch(
        updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
      );
  }, [passageData]);

  /*******************************************
   * Step 6: Once the button is re-enabled:
   * (1) If passage data existes, Upload the data
   * to firebase
   * (2) If no passageData, set isDataUploaded to true
   * to continue without upload
   ********************************************/
  useEffect(() => {
    const uploadPassage = async () => {
      console.log("passageData:", passageData);
      if (
        passageData &&
        typeof passageData === "object" &&
        Object.keys(passageData).length > 0 &&
        passageData.transcripts[0].content !== null &&
        !allScenariosUploaded
      ) {
        // (1) Upload the data to firebase
        try {
          const uploadResponse = await uploadToFirebase({
            passageData,
            userId,
            setIsDataUploaded,
            typeNumber,
            ...(currentScenario.meta ? { meta: currentScenario.meta } : {}),
          });
          console.log("Upload Response:", uploadResponse);
          return "Upload Successful", passageData;
        } catch (error) {
          console.error("Error uploading data:", error);
        }
      }
      // (2) if no passageData, set isDataUploaded to true
      // To continue execution without uploading
      else if (passageData === null && !allScenariosUploaded) {
        console.log(
          "Setting isDataUploaded to true: isDataUploaded:",
          isDataUploaded
        );
        setIsDataUploaded(true);
      }
    };
    console.log(
      "CURRENTSCENARIOCOMPLETE:",
      currentScenarioComplete,
      "nextBtnDisabled:",
      nextBtnDisabled,
      "isDataUploaded:",
      isDataUploaded,
      "passageData:",
      passageData,
      "allScenariosUploaded:",
      allScenariosUploaded
    );
    // Execute async funciont uploadPassage
    if (currentScenarioComplete) {
      uploadPassage();
    }
  }, [currentScenarioComplete]);

  /****************************************
   * Step 7: When the speak time runs out:
   * (1) Enable the button
   ****************************************/
  useEffect(() => {
    // (1) Enable the button
    if (microphoneSpeakTimeComplete && !currentScenarioComplete) {
      dispatch(
        updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
      );
    }
  }, [microphoneSpeakTimeComplete]);

  /*******************************************************
   * Step 8: Click Next Button when Speak time is complete
   * (1) Click the button
   ******************************************************/
  useEffect(() => {
    // (1) Click the button
    if (!nextBtnDisabled && microphoneSpeakTimeComplete) {
      console.log(
        "Reseting Data: currentScenarioComplete",
        currentScenarioComplete,
        "adviceLenght:",
        adviceScenarios.length
      );

      nextBtnRef.current.click();
    }
  }, [nextBtnDisabled, microphoneSpeakTimeComplete]);
  /***************************************************************/

  return (
    <div className="advice-container">
      <div className="details-container">
        <div className="description-wrapper">
          <div className="title">{currentScenario?.title}</div>
          <div className="details">{currentScenario?.details}</div>
          <div className="instructions">{currentScenario?.instructions}</div>
        </div>
        {/* {console.log(
          "activityCountdownTimerComplete:",
          activityCountdownTimerComplete,
          "currentLayout:",
          currentLayout
        )} */}
        {currentLayout === "advice-instruction-review" &&
          !activityCountdownTimerComplete ? (
          // HIDING THIS ELEMENT
          <div className="counters-and-microphone">
            <CountDownTimer
              isTimerComplete={activityCountdownTimerComplete}
              setIsTimerComplete={setActivityCountdownTimerComplete}
              disableCircularProgressbar={false}
              setCurrentCountdownTime={setCurrentCountdownTime}
              classLabel="hide"
            />
            {countdownDuration && typeof countdownDuration === "number" ? (
              <CountDownBar
                countdownDuration={countdownDuration}
                countdownUnit={countdownUnit}
                timeLeft={currentCountdownTime}
                overrideActivityCounter={setActivityCountdownTimerComplete}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          /*******************************************
           *  REPLACED DISAPPEARREAPPEARCLOCK
           * See Readme
           *******************************************/
          <div className="microphone-wrapper">
            <div className="recording-message">{`You have ${recordingDuration} ${recordingUnit} to speak...`}</div>
            {/* Microphones WIth CircularProgressIndicator */}
            <div className="microphone-container">
              {activityCountdownTimerComplete ? (
                <>
                  <CircularProgressIndicator2
                    classLabel="time-test"
                    defaultValue={0}
                  />
                  <ActiveMicrophone2
                    isTimerComplete={activityCountdownTimerComplete}
                    type={type}
                    isDataUploaded={isDataUploaded}
                    setSpeakTimeComplete={setMicrophoneSpeakTimeComplete}
                    // recordingDuration={recordingDuration}
                    recordingDuration={180}
                    // recordingUnit={recordingUnit}
                    recordingUnit={"seconds"}
                  />
                </>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {displayImages()}
      <div>
        <button className="hide" onClick={notify} id="animate.css">
          Notify !
        </button>
        <ToastContainer
          limit={1}
          autoClose={6000}
          className="toast-container"
        />
      </div>
    </div>
  );
};

export default WithRecording(AdviceInstructionReview);
