import * as images from "./images";
import * as audio from "./audio";

const scenarioData = {
  admin: [
    {
        name: "Jouyoung Lee",
        email: "pearson.tester33@gmail.com",
        password: "Keep0ntesting!",
    }],
  global: {
    "audio-recorded": false,
    nextBtnDisabled: false,
    lastName: "",
    id: "234",
    "is-audio-detected": false,
    svgs: {
      microphone: {
        inactive: images.indian,
        active: [images.indian, images.indian],
      },
    },
    // Begin Passage State
    "current-activity-type": "passage",
    "current-layout": "language-select",
    "current-stepper-segment": "language-select",

    // Begin Advice State *
    // "current-activity-type": "advice",
    // "current-layout": "advice-instructions",
    // "current-stepper-segment": "advice-instructions",

    // Spoken Chat State
    // "current-activity-type": "chat",
    // "current-layout": "chat-instructions",
    // "current-stepper-segment": "chat-instructions",

    // Written Chat State
    // "current-activity-type": "chat-written",
    // "current-layout": "chat-written-instructions",
    // "current-stepper-segment": "chat-written-instructions",

    // Birthday Chat State:
    //  "current-activity-type": "chat",
    // "current-layout": "chat-instruction-review",
    // "current-stepper-segment": "chat-safari",

    // Opinion State
    // "current-activity-type": "opinion",
    // "current-layout": "opinion-instructions",
    // "current-stepper-segment": "opinion-instructions",

    // Completion State:
    // "current-activity-type": "completion",
    // "current-layout": "completion",
    // "current-stepper-segment": "completion",

    scenario: 0,
    "page-names": [
      "passage",
      "advice",
      "chat",
      "chat-written",
      "opinion",
      "completion",
    ],
    "layout-names": [
      "language-select",
      "instructions",
      "passage-foreign",
      "passage-english",
      "advice-instructions",
      "advice-instruction-review",
      "chat-instructions",
      "chat-instruction-review",
      "chat-written-instructions",
      "chat-written-instruction-review",
      "opinion-instructions",
      "opinion-instruction-review",
      "completion",
    ],
    subviews: {
      passage: [
        "language-select",
        "instructions",
        "language-countdown",
        "passage-foreign",
        "passage-english",
        "translation-countdown",
      ],
      advice: [
        "advice-instructions",
        // "advice-back-pain",
        "advice-sunburn",
        "advice-trip",
        "advice-new-backpack",
        "advice-exchange-student",
      ],
      chat: [
        "chat-instructions",
        // "chat-spoken-office",
        "chat-life-style",
        "chat-safari",
      ],
      "chat-written": [
        "chat-written-instructions",
        // "chat-written-office",
        "chat-written-birthday",
        "chat-written-assignment",

      ],
      opinion: [
        "opinion-instructions",
        // "opinion-presentation-styles",
        "opinion-study-options",
        "opinion-tap-water",
        "opinion-part-time-job",
        "opinion-pet-options",
      ],
      completion: ["completion"],
    },
    "stepper-segment-names": [
      "language-select",
      "instructions",
      "language-countdown",
      "passage-foreign",
      "passage-english",
      "translation-countdown",
      "advice-instructions",
      // "advice-back-pain",
      "advice-sunburn",
      "advice-trip",
      "advice-new-backpack",
      "advice-exchange-student",
      "chat-instructions",
      // "chat-spoken-office",
      "chat-life-style",
      "chat-safari",
      "chat-written-instructions",
      // "chat-written-office",
      "chat-written-birthday",
      "chat-written-assignment",
      "opinion-instructions",
      // "opinion-presentation-styles",
      "opinion-study-options",
      "opinion-tap-water",
      "opinion-part-time-job",
      "opinion-pet-options",
      "completion",
    ],
  },
  passage: {
    isSilent: false,
    complete: false,
    "next-btn": {},
    "circular-progress-percentage": 0,
    languages: [
      {
        value: "2",
        label: "한국어",
        name: "Korean",
        code: "ko",
        imagePath: images.korean,
      },
      {
        value: "3",
        label: "中文 (文言文)",
        name: "Chinese",
        code: "lzh",
        imagePath: images.chinese,
      },
      {
        value: "4",
        label: "Español",
        name: "Spanish",
        code: "es",
        imagePath: images.spanish,
      },
      {
        value: "5",
        label: "Português",
        name: "Portuguese",
        code: "pt",
        imagePath: images.portuguese,
      },
      {
        value: "6",
        label: "हिन्दी",
        name: "Hindi",
        code: "hi",
        imagePath: images.indian,
      },
      {
        value: "7",
        label: "English",
        name: "English",
        code: "en",
        imagePath: images.english,
      },
    ],
    type: "passageTranslation",
    shiftedAudioComplete: false,
    shiftedAudioIndexes: [1, 2, 3, 4, 5, 6],
    shiftedAudioIndex: 2,
    shiftedAudio: {},
    translations_copy: [],
    "native-language": "",
    "audio-translations": {
      chinese: {
        audios: [audio.chi1, audio.chi2, audio.chi3, audio.chi4,],
        complete: false,
      },
      english: {
        audios: [audio.eng1, audio.eng2, audio.eng3, audio.eng4,],
        complete: false,
      },
      hindi: {
        audios: [audio.hin1, audio.hin2, audio.hin3, audio.hin4,],
        complete: false,
      },
      korean: {
        audios: [audio.kor1, audio.kor2, audio.kor3, audio.kor4,],
        complete: false,
      },
      portuguese: {
        audios: [audio.por1, audio.por2, audio.por3, audio.por4,],
        complete: false,
      },
      spanish: {
        audios: [audio.spa1, audio.spa2, audio.spa3, audio.spa4,],
        complete: false,
      },
    },

    views: {
      "language-select": {
        footer: {
          title: "Retell a passage",
          label: "0 of 6",
          clock: false,
        },
        main: {
          columns: [
            {
              left: {
                title: "Choose your first language",
                body: "This language will be used to present certain information to you for translation.",
              },
            },
            {
              right: "StaticSelect",
            },
          ],
        },
      },
      instructions: {
        footer: {
          label: "2 of 6",
          title: "Retell a passage",
          clock: true,
        },
        main: {
          columns: [
            {
              left: {
                title: "Retell a passage",
                body: "Listen to a passage and tell it back in English. ",
                "duration-message":
                  "You have 2 minutes to speak. If you finish early, you can press the Next button to move on.",
              },
            },
            {
              right: {
                component: "VideoPlayer",
                data: {
                  blockType: "",
                  activityType: "Video",
                  title: "Item 1: Instructions”",
                  time: "28 secs",
                  content: {
                    instructions: "Watch the video",
                    video: {
                      videoUrl: "https://www.youtube.com/embed/ldRLmQ2xkGI",
                      caption:
                        "Item 1: Instructions | PTE-Academic",
                      author: "Pearson Education",
                      width: "847",
                      height: "552",
                    },
                  },
                },
              },
            },
          ],
        },
      },
      "language-countdown": {
        footer: {
          title: "Retell a passage",
          label: "3 of 6",
          clock: true,
        },
      },
      "passage-foreign": {
        "recording-time-message": "Listen to this passage.",
        footer: {
          title: "Retell a passage",
          label: "3 of 6",
          clock: true,
        },
      },
      "translation-countdown": {
        footer: {
          title: "Retell a passage",
          label: "3 of 6",
          clock: true,
        },
      },
      "passage-english": {
        "recording-time-message": "You have 2 minutes to speak.",
        footer: {
          title: "Retell a passage",
          label: "3 of 6",
          clock: true,
        },
      },
    },
  },
  advice: {
    "all-scenarios-uploaded": false,
    complete: false,
    "circular-progress-percentage": 100,
    shiftedItemIndex: 0,
    shiftedItem: {},
    "next-btn": {},
    views: {
      "advice-instructions": {
        footer: {
          label: "1 of 5",
          // title: "Speak from pictures",
          title: "Talk to a partner using pictures",

          clock: true,
        },
        main: {
          columns: [
            {
              left: {
                title: "Talk to a partner using pictures",
                body: "Read a situation and talk to your partner based on the pictures. ",
                "duration-message":
                  "You have 45 seconds to prepare and 3 minutes to speak. If you finish early, you can press the Next button to move on.",
              },
            },
            {
              right: {
                component: "VideoPlayer",
                data: {
                  blockType: "",
                  activityType: "Video",
                  title: "Item 2: Instructions",
                  time: "27 secs",
                  content: {
                    instructions: "Watch the video",
                    video: {
                      videoUrl: "https://www.youtube.com/embed/ZN7NfEn3BOM",
                      caption:
                        "Item 2: Instructions | PTE-Academic",
                      author: "Pearson Education",
                      width: "847",
                      height: "552",
                    },
                  },
                },
              },
            },
          ],
        },
      },
      // "advice-back-pain": {
      //   footer: {
      //     label: "2 of 5",
      //     title: "Talk to a partner using pictures",
      //     clock: true,
      //   },
      // },
      "advice-sunburn": {
        footer: {
          label: "2 of 5",
          title: "Talk to a partner using pictures",
          clock: true,
        },
      },
      "advice-trip": {
        footer: {
          label: "3 of 5",
          title: "Talk to a partner using pictures",
          clock: true,
        },
      },
      "advice-new-backpack": {
        footer: {
          label: "4 of 5",
          title: "Talk to a partner using pictures",
          clock: true,
        },
      },
      "advice-exchange-student": {
        footer: {
          label: "5 of 5",
          title: "Talk to a partner using pictures",
          clock: true,
        },
      },

    },
    scenarios: [
      // {
      //   meta: "backpain",
      //   title: "Situation",
      //   details:
      //     "Your friend has recently been experiencing back pain. She is asking you for advice on what to do about it.",
      //   instructions:
      //     "Based on the pictures, tell her what she should and shouldn’t do. Be sure to include details and explanations for each suggestion.",
      //   "countdown-duration": 45,
      //   "countdown-duration-unit": "seconds",
      //   "recording-duration": 3,
      //   "recording-duration-unit": "minutes",
      //   images: [
      //     images.back1,
      //     images.back2,
      //     images.back3,
      //     images.back4,
      //     images.back5,
      //     images.back6,
      //   ],
      //   complete: false,
      // },
      {
        meta: "sunburn",
        title: "Situation",
        details:
          "Your friend is going to the beach tomorrow. She wants to know the best ways to protect herself from sunburn.",
        instructions:
          "Using the pictures, give her some suggestions on how to avoid sunburn. Make sure to talk about each idea with some details and explanation.",
        "countdown-duration": 45,
        "countdown-duration-unit": "seconds",
        "recording-duration": 3,
        "recording-duration-unit": "minutes",
        images: [
          images.sun1,
          images.sun2,
          images.sun3,
          images.sun4,
          images.sun5,
          images.sun6,
        ],
        complete: false,
      },
      {
        meta: "trip",
        title: "Situation",
        details:
          "You recently visited Yellowstone National Park. On your way to class, you met your classmate, and she would like to hear about your experience.",
        instructions:
          "Using the pictures, describe what you did during your trip. Be sure to talk about specific details for each activity.",
        "countdown-duration": 45,
        "countdown-duration-unit": "seconds",
        "recording-duration": 3,
        "recording-duration-unit": "minutes",
        images: [
          images.tp1,
          images.tp2,
          images.tp3,
          images.tp4,
          images.tp5,
          images.tp6,
        ],
        complete: false,
      },
      {
        meta: "new-backpack",
        title: "Situation",
        details:
          "You recently bought a new backpack. Your friend is interested in getting a new backpack too and is asking about your experience with your new one.",
        instructions:
          "Based on the pictures provided, share what you like and don’t like about the backpack. Be sure to explain each point with some details.",
        "countdown-duration": 45,
        "countdown-duration-unit": "seconds",
        "recording-duration": 3,
        "recording-duration-unit": "minutes",
        images: [
          images.bag1,
          images.bag2,
          images.bag3,
          images.bag4,
          images.bag5,
          images.bag6,
        ],
        complete: false,
      },
      {
        meta: "exchange-student",
        title: "Situation",
        details:
          "A student from another country will be staying with your family for one month.",
        instructions:
          "Using the pictures provided, let him know some things about your family and your home so that he has the right expectations.",
        "countdown-duration": 45,
        "countdown-duration-unit": "seconds",
        "recording-duration": 3,
        "recording-duration-unit": "minutes",
        images: [
          images.exchange1,
          images.exchange2,
          images.exchange3,
          images.exchange4,
          images.exchange5,
          images.exchange6,
        ],
        complete: false,
      },
    ],
    scenarios_copy: [],
  },
  chat: {
    "all-scenarios-uploaded": false,
    complete: false,
    "circular-progress-percentage": 0,
    shiftedItemIndex: 0,
    shiftedItem: {},
    "next-btn": {},
    views: {
      "chat-instructions": {
        footer: {
          label: "1 of 3",
          title: "Talk to your partner about changes to plans",
          clock: true,
        },
        main: {
          columns: [
            {
              left: {
                title: "Talk to your partner about changes to plans",
                body: "Recently, you and your conversation partner talked about some plans. The pictures on the top show the original plans. However, you have made some changes. The pictures at the bottom show the new plans.",
                "duration-message":
                  "Talk to your partner about the changes in a conversation.",
              },
            },
            {
              right: {
                component: "VideoPlayer",
                data: {
                  blockType: "",
                  activityType: "Video",
                  title: "Item 3 (spoken): Instructions",
                  time: "46 secs",
                  content: {
                    instructions: "Watch the video",
                    video: {
                      videoUrl: "https://www.youtube.com/embed/JWCknVh-90E",
                      caption:
                        "Item 3 (spoken): Instructions | PTE-Academic",
                      author: "Pearson Education",
                      width: "847",
                      height: "552",
                    },
                  },
                },
              },
            },
          ],
        },
      },
      // "chat-spoken-office": {
      //   footer: {
      //     label: "2 of 4",
      //     title: "Talk to your partner about changes to plans",
      //     clock: true,
      //   },
      // },
      "chat-life-style": {
        footer: {
          label: "2 of 3",
          title: "Talk to your partner about changes to plans",
          clock: true,
        },
      },
      "chat-safari": {
        footer: {
          label: "3 of 3",
          title: "Talk to your partner about changes to plans",
          clock: true,
        },
      },
    },
    scenarios: [
      // {
      //   meta: "office-spoken",
      //   type: "spoken",
      //   title: "Situation",
      //   question: "Hey! Have you visited the new office yet?",
      //   prompt:
      //     "You are an office employee, your name is Ben.  Your office is relocating to another building, but you have not been able to visit.  However, your colleague visited the new facility last week.  You are curious to find out if there are any differences between the work area, conference room, cafeteria, or relaxation area.  Have a polite conversation with your friend to determine the changes.  Your friend will be speaking to you in English. If you hear anything that sounds like a different language please disregard it, or ask them to repeat their comment as you didn't hear it clearly.  Keep your questions and responses concise in no more than two sentences.",
      //   details:
      //     "Your company recently moved the office to a new building. Your friend, Ben, has been to your old office, but not the new one.",
      //   instructions:
      //     "Tell Ben about the changes using the pictures provided. Be sure to provide specific details, sharing how you feel about the change, and comparing the old and new offices.",
      //   "countdown-duration": 1,
      //   "countdown-duration-unit": "minute",
      //   "recording-duration": 2,
      //   "recording-duration-unit": "minutes",
      //   "original-label": "CURRENT OFFICE",
      //   "new-label": "NEW OFFICE",
      //   images: [
      //     [images.off1, images.off2, images.off3, images.off4],
      //     ["Work area", "Conference room", "Cafeteria", "Relaxation area"],
      //     [images.arrow],
      //     [images.off5, images.off6, images.off7, images.off8],
      //     ["Work area", "Conference room", "Cafeteria", "Relaxation area"],
      //   ],
      //   complete: false,
      // },
      {
        meta: "life-style-spoken",
        type: "spoken",
        title: "Situation",
        question: "Hey! Have you started implementing your healthier lifestyle plan yet?",
        prompt:
          "Your name is Jose, and you are talking to a friend about his healthy lifestyle changes. Have a polite conversation with him to determine the specific lifestyle changes he has made. Ask individual questions regarding his sleep schedule, exercise routine, work environment, and hobbies. Keep your questions and responses concise, with no more than two sentences.",
        details:
          "Last time you talked with your friend, Jose, you mentioned wanting to have a healthier lifestyle.",
        instructions:
          "Now, Jose wants to hear about the changes you’ve made. Describe your new routines and explain why you decided to make those changes.",
        "countdown-duration": 1,
        "countdown-duration-unit": "minute",
        "recording-duration": 2,
        "recording-duration-unit": "minutes",
        "original-label": "OLD LIFESTYLE",
        "new-label": "NEW LIFESTYLE",
        images: [
          [images.life1, images.life2, images.life3, images.life4],
          ["Sleep", "Exercise", "Work", "Hobby"],
          [images.arrow],
          [images.life5, images.life6, images.life7, images.life8],
          ["Sleep", "Exercise", "Work", "Hobby"],
        ],
        complete: false,
      },
      {
        meta: "safari-spoken",
        type: "spoken",
        title: "Situation",
        question: "Hey! How was safari holiday in Africa?",
        prompt:
        "You are chatting with a friend about his safari holiday in Africa. Have a polite conversation with him to explore the differences between what was advertised and his actual experience. Ask individual questions about the discrepancies in meals, lodging, tours, and weather. Keep your questions and responses concise, with no more than two sentences.",
        details:
          "You just got back from a safari holiday in Africa. Your friend, John, heard about your plans about a month ago",
        instructions:
          "Tell him how it went, including details about what the advertisement said and how it really happened.",
        "countdown-duration": 1,
        "countdown-duration-unit": "minute",
        "recording-duration": 2,
        "recording-duration-unit": "minutes",
        "original-label": "ORIGINAL PLAN",
        "new-label": "WHAT ACTUALLY HAPPENED",
        images: [
          [images.saf1, images.saf2, images.saf3, images.saf4],
          ["Meal", "Place to stay", "Safari tour", "Weather"],
          [images.arrow],
          [images.saf5, images.saf6, images.saf7, images.saf8],
          ["Meal", "Place to stay", "Safari tour", "Weather"],
        ],
        complete: false,
      },
    ],
    scenarios_copy: [],
  },
  "chat-written": {
    "all-scenarios-uploaded": false,
    complete: false,
    "circular-progress-percentage": 0,
    shiftedItemIndex: 0,
    shiftedItem: {},
    "next-btn": {},
    views: {
      "chat-written-instructions": {
        footer: {
          label: "1 of 3",
          title: "Chat with your partner about changes to plans",
          clock: true,
        },
        main: {
          columns: [
            {
              left: {
                title: "Chat with your partner about changes to plans",
                body: "Recently, you and your conversation partner talked about some plans. The pictures on the top show the original plans. However, you have made some changes to the plans. The pictures at the bottom show the new plans.",
                "duration-message":
                  "Chat with your partner about the changes in a conversation.",
              },
            },
            {
              right: {
                component: "VideoPlayer",
                data: {
                  blockType: "",
                  activityType: "Video",
                  title: "“Item 3 (written): Instructions",
                  time: "26 secs",
                  content: {
                    instructions: "Watch the video",
                    video: {
                      videoUrl: "https://www.youtube.com/embed/wO5ajl8netc",
                      caption:
                        "Item 3 (written): Instructions | PTE-Academic",
                      author: "Pearson Educationc",
                      width: "847",
                      height: "552",
                    },
                  },
                },
              },
            },
          ],
        },
      },
      // "chat-written-office": {
      //   footer: {
      //     label: "2 of 4",
      //     title: "Chat with your partner about changes to plans",
      //     clock: true,
      //   },
      // },
      "chat-written-birthday": {
        footer: {
          label: "2 of 3",
          title: "Chat with your partner about changes to plans",
          clock: true,
        },
      },
      "chat-written-assignment": {
        footer: {
          label: "3 of 3",
          title: "Chat with your partner about changes to plans",
          clock: true,
        },
      },

    },
    scenarios: [
      // {
      //   meta: "office-written",
      //   type: "written",
      //   title: "Situation",
      //   question: "Hey! Have you visited the new office yet?",
      //   prompt:
      //     "You are an office employee, your name is Ben.  Your office is relocating to another building, but you have not been able to visit.  However, your colleague visited the new facility last week.  You are curious to find out if there are any differences between the work area, conference room, cafeteria, or relaxation area.  Have a polite conversation with your friend to determine the changes.  Your friend will be speaking to you in English. If you hear anything that sounds like a different language please disregard it, or ask them to repeat their comment as you didn't hear it clearly.  Keep your questions and responses concise in no more than two sentences.",
      //   details:
      //     "Your company recently moved the office to a new building. Your friend, Ben, has been to your old office, but not the new one.",
      //   instructions:
      //     "Tell Ben about the changes using the pictures provided. Be sure to provide specific details, sharing how you feel about the change, and comparing the old and new offices.",
      //   "countdown-duration": 1,
      //   "countdown-duration-unit": "minute",
      //   "recording-duration": 2,
      //   "recording-duration-unit": "minutes",
      //   "original-label": "CURRENT OFFICE",
      //   "new-label": "NEW OFFICE",
      //   images: [
      //     [images.off1, images.off2, images.off3, images.off4],
      //     ["Work area", "Conference room", "Cafeteria", "Relaxation area"],
      //     [images.arrow],
      //     [images.off5, images.off6, images.off7, images.off8],
      //     ["Work area", "Conference room", "Cafeteria", "Relaxation area"],
      //   ],
      //   complete: false,
      //   notification: "Ben is typing...",
      // },
      {
        meta: "birthday-written",
        type: "written",
        title: "Situation",
        question: "Hey! How's the birthday party prep coming along?",
        prompt:
          "Your name is Maria. Your friend is planning a birthday party, but the original plans have changed.  You are curious to find out if the date, location, food options, or activities have changed.  Have a polite conversation with your friend to determine the changes.  Your friend will be speaking to you in English. If you hear anything that sounds like a different language please disregard it, or ask them to repeat their comment, as you didn't hear it clearly.  Keep your questions and responses concise in no more than two short sentences.",
        details:
          "It’s your birthday, and you told Maria a few weeks ago about your plans to have a party with your friends. The images at the top show what you told Maria, but things have changed as shown in the images at the bottom.",
        instructions:
          "Describe the new plans to Maria, making sure to explain each change, including the reasons behind the changes and any specific details.",
        "countdown-duration": 1,
        "countdown-duration-unit": "minute",
        "recording-duration": 2,
        "recording-duration-unit": "minutes",
        "original-label": "ORIGINAL PLAN",
        "new-label": "NEW PLAN",
        images: [
          [images.birth1, images.birth2, images.birth3, images.birth4],
          ["Date / Time", "Place", "Food", "Activity"],
          [images.arrow],
          [images.birth5, images.birth6, images.birth7, images.birth8],
          ["Date / Time", "Place", "Food", "Activity"],
        ],
        complete: false,
        notification: "Maria is typing...",
      },
      {
        meta: "assignment-written",
        type: "written",
        title: "Situation",
        question:
          "Hey, have you looked at the changes to our writing assignment yet?",
        prompt:
          "You and your friend are taking a writing class together, your name is Mina.  Your teacher has given you an assignment, but the details have changed.  You are curious to find out if there are any changes to the due date, length, submission, or grading details.  Have a polite conversation with your friend to determine the changes.  Your friend will be speaking to you in English. If you hear anything that sounds like a different language please disregard it, or ask them to repeat their comment as you didn't hear it clearly.  Keep your questions and responses concise in no more than two short sentences.",
        details:
          "You and your friend, Mina, are in a writing course, and an assignment is due soon. The professor recently made some changes to the assignment requirements.",
        instructions:
          "Mina missed that class, so you need to explain the changes to her using the second set of pictures. Also, tell her why the instructor made these changes.",
        "countdown-duration": 1,
        "countdown-duration-unit": "minute",
        "recording-duration": 2,
        "recording-duration-unit": "minutes",
        "original-label": "ORIGINAL DETAILS",
        "new-label": "NEW DETAILS",
        images: [
          [images.assign1, images.assign2, images.assign3, images.assign4],
          ["Due Date", "Length", "Submission", "Grading Rubric"],
          [images.arrow],
          [images.assign5, images.assign6, images.assign7, images.assign8],
          ["Due Date", "Length", "Submission", "Grading Rubric"],
        ],
        complete: false,
        notification: "Mina is typing...",
      },

    ],
    scenarios_copy: [],
  },
  opinion: {
    "all-scenarios-uploaded": false,
    complete: false,
    "circular-progress-percentage": 0,
    shiftedItemIndex: 0,
    shiftedItem: {},
    "next-btn": {},
    views: {
      "opinion-instructions": {
        footer: {
          label: "1 of 5",
          title: "Give your opinion",
          clock: true,
        },
        main: {
          columns: [
            {
              left: {
                title: "Give your opinion",
                body: "You will be given a situation with a set of images. Your conversation partner will ask some questions or request feedback. Share your thoughts.",
                "duration-message": "",
              },
            },
            {
              right: {
                component: "VideoPlayer",
                data: {
                  blockType: "",
                  activityType: "Video",
                  title: "Item 4: Instructions",
                  time: "33 secs",
                  content: {
                    instructions: "Watch the video",
                    video: {
                      videoUrl: "https://www.youtube.com/embed/roPypbFhUJw",
                      caption:
                        "Item 4: Instructions | PTE-Academic",
                      author: "Pearson Education",
                      width: "847",
                      height: "552",
                    },
                  },
                },
              },
            },
          ],
        },
      },
      // "opinion-presentation-styles": {
      //   footer: {
      //     label: "2 of 6",
      //     title: "Give your opinion",
      //     clock: true,
      //   },
      // },
      "opinion-study-options": {
        footer: {
          label: "2 of 5",
          title: "Give your opinion",
          clock: true,
        },
      },
      "opinion-tap-water": {
        footer: {
          label: "3 of 5",
          title: "Give your opinion",
          clock: true,
        },
      },
      "opinion-part-time-job": {
        footer: {
          label: "4 of 5",
          title: "Give your opinion",
          clock: true,
        },
      },
      "opinion-pet-options": {
        footer: {
          label: "5 of 5",
          title: "Give your opinion",
          clock: true,
        },
      },
    },
    scenarios: [
      // {
      //   meta: "opinion-presentation-styles",
      //   title: "Situation",
      //   prompt:
      //     "You will deliver a 10 minute presentaion tomorow at work, during a team meeting.  You have identified four different presentation styles. You would like to discuss the styles with your colleague and obtain their opinion on each style.  Have a polite conversation with one of your colleague about the styles.  Your colleague will be speaking to you in English. If you hear anything that sounds like a different language please disregard it, or ask them to repeat their comment, as you didn't hear it clearly.  Keep your questions and responses concise in no more than two sentences.",
      //   details:
      //     "Your colleague has a 20-minute presentation next week during a team meeting. He will be sharing results from his recent research.",
      //   instructions:
      //     "He is thinking about four different ways to present this research, and he wants your opinion. Answer his questions about each option.",
      //   "countdown-duration": 1,
      //   "countdown-duration-unit": "minute",
      //   "recording-duration": 2,
      //   "recording-duration-unit": "minutes",
      //   "image-style": "with-label",
      //   images: [
      //     [images.pres1, images.pres2, images.pres3, images.pres4],
      //     [
      //       "Printed handout",
      //       "Presentation with images",
      //       "Just talk",
      //       "Workshop",
      //     ],
      //   ],
      //   complete: false,
      //   questions: [
      //     "How do you feel about using a printed handout to share my research results?",
      //     "What do you think about using images in a presentation to explain the findings?",
      //     "What’s your opinion on simply talking through the results without any visuals?",
      //     "How do you feel about turning the presentation into a workshop with interactive activities?",
      //     "After considering all the options, which one do you think would be the best choice for my presentation? Why?",
      //   ],
      // },
      {
        meta: "opinion-study-options",
        title: "Situation",
        prompt:
          "You are an alien psychiatrist.  It is considered offensive to ask your patients any question.  Your goal is to be polite by responding only to their thoughts and not being rude by asking your own followup questions at any point during discussion.  You can only respond to your patients with two phrases and nothing more, 'Thank you for your feedback', when you understand, and 'I'm sorry, I didn't hear that, can you please repeat', when you do not understand.",
        details:
          "You and your friends are working on a group project for a class. You’ve planned to meet tomorrow to work on it.",
        instructions:
          "Your friend has suggested four different places for the meeting and wants to hear your thoughts. Respond to his questions about each location.",
        "countdown-duration": 1,
        "countdown-duration-unit": "minute",
        "recording-duration": 2,
        "recording-duration-unit": "minutes",
        "image-style": "with-label",
        images: [
          [images.study1, images.study2, images.study3, images.study4],
          ["Coffee shop", "Library", "Friend’s home", "Outdoor campus"],
        ],
        complete: false,
        questions: [
          "What’s your overall impression of the coffee shop as a meeting spot?",
          "How do you feel about using the library for our group work?",
          "What do you think about meeting at my place for our project?",
          "What do you think about working outside on the campus?",
          "Which place do you like best for our meeting? Why?",
        ],
      },
      {
        meta: "opinion-tap-water",
        title: "Situation",
        prompt:
          "While  helping to improve a public bathroom in a parks, you discover the tap-water isn't safe to drink.  You have created 4 different warning signs about the top water and your interested in obtaining feedback from your friend.  Have a polite conversation with your friend about the designs.  Your friend will be speaking to you in English. If you hear anything that sounds like a different language please disregard it, or ask them to repeat their comment, as you didn't hear it clearly.  Keep your questions and responses concise in no more than two sentences.",
        details:
          "Your friend is helping to improve a public bathroom in a park. The tap water isn’t safe to drink, so he wants to put up a warning sign.",
        instructions:
          "He has created four different designs and wants your opinion. Answer his questions about each design.",
        "countdown-duration": 1,
        "countdown-duration-unit": "minute",
        "recording-duration": 2,
        "recording-duration-unit": "minutes",
        "image-style": "without-label",
        images: [
          [images.tap1, images.tap2, images.tap3, images.tap4],
          ["Simple icon", "Person icon", "Text only", "Text and symbols"],
        ],
        complete: false,
        questions: [
          "What do you think of this simple icon to show that the water isn’t safe to drink?",
          "How do you feel about using a person drinking with a red circle to get the message across?",
          "What’s your opinion on using just text to warn people that the water isn’t safe?",
          "How do you think this combination of text and symbols works for a warning sign?",
          "After looking at all the designs, which one do you think is the best choice for the sign? Why?",
        ],
      },
      {
        meta: "opinion-part-time-job",
        title: "Situation",
        prompt:
          "You are interested in helping your friend find a new part-time job.  You have suggested four options and would like to know what you friend thinks about them.  Have a polite conversation with your friend about the options.  Your friend will be speaking to you in English. If you hear anything that sounds like a different language please disregard it, or ask them to repeat their comment, as you didn't hear it clearly.  Keep your questions and responses concise in no more than two sentences.",
        details:
          "You mentioned to your friend that you’re looking for a part-time job.",
        instructions:
          "He has suggested four options and wants to know what you think about each one.",
        "countdown-duration": 1,
        "countdown-duration-unit": "minute",
        "recording-duration": 2,
        "recording-duration-unit": "minutes",
        "image-style": "without-label",
        images: [
          [images.job1, images.job2, images.job3, images.job4],
          [
            "Ride sharing",
            "Fast food restaurant",
            "Package delivery",
            "Babysitting",
          ],
        ],
        complete: false,
        questions: [
          "What do you think about using your own car to drive for a ride sharing service like Uber?",
          "How do you feel about working at a fast food restaurant? Do you think it would be a good fit for you?",
          "What’s your opinion on delivering packages? Do you think you'd like the independence of working on your own?",
          "How do you feel about babysitting? Do you enjoy spending time with kids?",
          "After considering all these options, which job do you think would be the best choice for you? Why?",
        ],
      },
      {
        meta: "opinion-pet-options",
        title: "Situation",
        prompt:
          "When you mentioned that you’re thinking about getting a pet, your friend Jamie suggested four possible options.",
        details:
          "When you mentioned that you’re thinking about getting a pet, your friend suggested four possible options.",
        instructions: "He’d like to hear what you think about each one.",
        "countdown-duration": 1,
        "countdown-duration-unit": "minute",
        "recording-duration": 2,
        "recording-duration-unit": "minutes",
        "image-style": "without-label",
        images: [
          [images.pet1, images.pet2, images.pet3, images.pet4],
          ["Dog", "Bird", "Snake", "Fish"],
        ],
        complete: false,
        questions: [
          "What do you think about getting a dog as a pet? Do you think you’d enjoy the companionship?",
          "How do you feel about having a bird? Do you think you’d like taking care of it?",
          "What’s your opinion on getting a snake? Do you feel comfortable with the idea of having one?",
          "How do you feel about having fish as pets? Do you think they’d be a relaxing choice?",
          "After thinking about all the options, which pet do you think would be the best fit for you? Why?",
        ],
      },
    ],
    scenarios_copy: [],
  },
  currentState: {
    type: "passageTranslation",
    scenario: "",
    step: 0,
  },
};

export default scenarioData;
