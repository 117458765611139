// import React and necessary hooks from 'react'
// import utility function to truncate text
import React, { useEffect, useState, useRef } from 'react';
import { truncateText } from '../utils/truncate'; // Adjust the path according to your file structure

/**
 * TextComponent is responsible for truncating a block of text based on its container's dimensions 
 * and maintaining the truncated state. It also updates the truncated text on window resize.
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The original text that needs to be truncated based on the container's size.
 *
 * @returns {JSX.Element} The rendered component containing the truncated text.
 */
const TextComponent = ({ text }) => {
  // State to manage the truncated version of the text
  const [truncatedText, setTruncatedText] = useState(text);

  // Reference to the container that holds the text
  const containerRef = useRef(null);

  /**
   * Updates the truncated text by calling a utility function to ensure the text fits within the container.
   * This function calculates the height of each line and truncates accordingly.
   */
  const updateTruncatedText = () => {
    const container = containerRef.current;
    if (container) {
      const lineHeight = getComputedStyle(container).lineHeight;
      const truncated = truncateText(text, container, lineHeight); // Truncate text based on container's dimensions
      setTruncatedText(truncated); // Update the state with the truncated text
    }
  };

  // useEffect to handle initial rendering and updates when the text changes or the window is resized
  useEffect(() => {
    updateTruncatedText(); // Initial truncation on component mount

    // Create a ResizeObserver to observe changes in the container's dimensions
    const resizeObserver = new ResizeObserver(() => {
      updateTruncatedText(); // Update truncated text when the container size changes
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current); // Start observing the container
    }

    // Cleanup: disconnect the observer when the component unmounts or text changes
    return () => {
      resizeObserver.disconnect();
    };
  }, [text]); // Re-run the effect when 'text' prop changes

  // Render the truncated text and preserve line breaks
  return (
    <span
      // title={text} 
      className="instructions" ref={containerRef}>
      {truncatedText.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {/* Add a line break (<br />) after each line except the last */}
          {index !== truncatedText.split('\n').length - 1 && <br />}
        </React.Fragment>
      ))}
    </span>
    // <span title={text} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px', display: 'inline-block' }}>
    //   {text}
    // </span>
  );
};

export default TextComponent;
