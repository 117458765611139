import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

const VideoPlayer = () => {
    const scenarioData = useSelector((state) => state.scenarios);
    console.log("Scenario Data33:", scenarioData);
    const currentLayout = scenarioData.global["current-layout"];
    
    const currentActivityType= scenarioData.global["current-activity-type"];
    console.log("Current Page:", currentActivityType);
    const root = scenarioData[currentActivityType];
    console.log("Current View:", currentLayout);
    // const languageOptions = root.languages;
    // const englishPassage = root["english-passage"]
    // console.log("English Passage:", englishPassage);
    // console.log("Scenario Data:", scenarioData);
    console.log("ROOT5:", root);
    const viewMainData = root.views[currentLayout].main;
    console.log("ViewMainData:", viewMainData);
    const columnsData = viewMainData.columns;
    const columnsNum = columnsData.length;
    console.log("ColumnsData:", columnsData, columnsNum);
    const leftData = columnsData[0].left;
    const rightData = columnsData[1].right;
    const videoData = rightData.data
    console.log("VIDEO DATA:", videoData);
    console.log("videoData", videoData)
    // const { videoUrl, width, height } = videoData;
    const videoUrl = videoData.content.video.videoUrl;
    const width = videoData.content.video.width;
    const height = videoData.content.video.height;
    const autoplay='?autoplay=1';
    return (
        <div className="video-block">
        <div className="video">
            <iframe 
                width={width}
                height={height} 
                src={videoUrl+autoplay} 
                title="YouTube video player"
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen
            >
            </iframe>  
        </div>
        <p className="source-caption">Source: <a>{videoUrl}</a></p>
        {/* {props.data.caption && <p className="video-caption">{props.data.caption}</p>} */}
    
            
    </div>
    );
};

export default VideoPlayer;